<template>
  <v-row class="ml-1 mr-1">
    <v-col sm="1">
      <div class="headbar-component p-2">
        <div>
          <v-img
            :src="require('@/assets/icons/lr-logo-mini.svg')"
            contain
            height="60"
            width="60"
          />
        </div>
      </div>
    </v-col>
    <v-col sm="10">
      <div class="headbar-component">
        <h2>{{ $t("WELCOME") }}</h2>
      </div>
    </v-col>
    <v-col sm="1">
      <div class="headbar-component flex-column">
        <p>
          <strong>{{ initials }}</strong>
        </p>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <p class="text-underlined-pointer" v-bind="attrs" v-on="on">
              <strong>{{ $t("ACCOUNT") }}</strong>
            </p>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in menuItems" :key="index">
              <v-list-item-title
                @click="onMenuItemClick(index)"
                style="cursor: pointer"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { LOGOUT } from "@/store/mutations"
export default {
  name: "Toolbar",
  data() {
    return {
      menuItems: [
        {
          title: this.$t("LOGOUT"),
          click() {
            this.logout()
            this.$router.push("/login")
          },
        },
        {
          title: this.$t("CHANGE_PASSWORD"),
          click() {
            this.$router.push("/change-password")
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    initials() {
      return this.user ? this.user.username.slice(0, 2).toUpperCase() : ""
    },
  },
  methods: {
    ...mapMutations([LOGOUT]),
    onMenuItemClick(index) {
      this.menuItems[index].click.call(this)
    },

    logout() {
      this.LOGOUT()
    },
  },
}
</script>

<style lang="scss" scoped>
.headbar-component {
  height: 100px;
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.text-underlined-pointer {
  text-decoration: underline;
  cursor: pointer;
}
</style>
