<template>
  <v-form ref="form" v-model="valid">
    <v-radio-group :rules="[requiredRule]" v-model="value.role" :row="true">
      <v-radio :label="teacherString" :value="teacherLowString"></v-radio>
      <v-radio :label="schoolAdministratorString" :value="administrationString"></v-radio>
    </v-radio-group>
    <v-row>
      <v-col sm="6">
        <v-text-field
          :rules="[requiredRule]"
          v-model="value.name"
          class="oh-input-field subscribe-input-field"
          height="50px"
          :placeholder="nameString"
          hide-details="auto"
          minlength="3"
          solo
        >
          <template slot="prepend-inner">
            <img
              :src="require('@/assets/icons/forms/info-client-icon.svg')"
              class="mr-2"
              height="30"
            />
          </template>
        </v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field
          :rules="[requiredRule]"
          v-model="value.surname"
          hide-details="auto"
          class="oh-input-field subscribe-input-field"
          height="50px"
          :placeholder="surnameString"
          minlength="3"
          solo
        >
          <template slot="prepend-inner">
            <img
              :src="require('@/assets/icons/forms/info-client-icon.svg')"
              class="mr-2"
              height="30"
            />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-text-field
      :rules="[requiredRule, emailRule]"
      v-model="value.email"
      class="oh-input-field subscribe-input-field"
      height="50px"
      hide-details="auto"
      :placeholder="emailString"
      minlength="3"
      solo
    >
      <template slot="prepend-inner">
        <img :src="require('@/assets/icons/forms/email-icon.svg')" class="mr-2" height="30" />
      </template>
    </v-text-field>
    <v-text-field
      :rules="[requiredRule, emailMatchesRule]"
      v-model="value.confirmEmail"
      class="oh-input-field subscribe-input-field"
      height="50px"
      :placeholder="confirmEmailAddressString"
      hide-details="auto"
      minlength="3"
      solo
    >
      <template slot="prepend-inner">
        <img :src="require('@/assets/icons/forms/email-icon.svg')" class="mr-2" height="30" />
      </template>
    </v-text-field>
    <v-text-field
      :rules="[requiredRule]"
      v-model="value.password"
      class="oh-input-field subscribe-input-field"
      height="50px"
      :type="passwordVisible ? 'text' : 'password'"
      :placeholder="passwordString"
      hide-details="auto"
      minlength="3"
      solo
    >
      <template slot="prepend-inner">
        <img :src="require('@/assets/icons/forms/lock-icon.svg')" class="mr-2" height="30" />
      </template>
      <template slot="append">
        <img
          :src="
            require(`@/assets/icons/${this.passwordVisible ? 'eye-block' : 'eye'
              }/${this.fieldsFocused[1] ? 'eye-active' : 'eye'}.svg`)
          "
          class="textfield-icon"
          :color="fieldsFocused[1] ? '#C5003E' : null"
          @click="passwordVisible = !passwordVisible"
          style="height: 20px; cursor: pointer"
        />
      </template>
    </v-text-field>
    <v-text-field
      :rules="[requiredRule, passwordMatchesRule]"
      v-model="value.confirmPassword"
      class="oh-input-field subscribe-input-field"
      height="50px"
      :placeholder="confirmPasswordString"
      :type="repeatPasswordVisible ? 'text' : 'password'"
      hide-details="auto"
      minlength="3"
      solo
    >
      <template slot="prepend-inner">
        <img :src="require('@/assets/icons/forms/lock-icon.svg')" class="mr-2" height="30" />
      </template>
      <template slot="append">
        <img
          :src="
            require(`@/assets/icons/${this.repeatPasswordVisible ? 'eye-block' : 'eye'
              }/${this.fieldsFocused[1] ? 'eye-active' : 'eye'}.svg`)
          "
          class="textfield-icon"
          :color="fieldsFocused[1] ? '#C5003E' : null"
          @click="repeatPasswordVisible = !repeatPasswordVisible"
          style="height: 20px; cursor: pointer"
        />
      </template>
    </v-text-field>
    <v-text-field
      :rules="[requiredRule, numericOnlyRule]"
      v-model="value.phoneNumber"
      class="oh-input-field subscribe-input-field"
      height="50px"
      :placeholder="phoneNumberString"
      hide-details="auto"
      minlength="3"
      solo
    >
      <template slot="prepend-inner">
        <img :src="require('@/assets/icons/forms/call-icon.svg')" class="mr-2" height="30" />
      </template>
    </v-text-field>
    <div>
      <p class="text-privacy mt-5">
        <strong class="uppercase">{{ $t("DATA_CONTROLLER") }}</strong>
        : {{ $t("DATA_CONTROLLER_CONTENT") }}
        |
        <strong
          class="uppercase"
        >{{ $t("PURPOSES") }}</strong>
        :{{ $t("PURPOSES_CONTENT") }}
        |
        <strong class="uppercase">{{ $t("STORAGE") }}</strong>
        : {{ $t("STORAGE_CONTENT") }}
        |
        <strong
          class="uppercase"
        >{{ $t("LEGAL_BASIS") }}</strong>
        : {{ $t("LEGAL_BASIS_CONTENT") }}
        |
        <strong
          class="uppercase"
        >{{ $t("RIGHTS") }}</strong>
        : {{ $t("RIGHTS_CONTENT") }}
        <a :href="`mailto:${rightsEmail}`">{{ $t("RIGHTS_EMAIL") }}</a>
        |
        <strong class="uppercase">{{ $t("ADDITIONAL_INFORMATION") }}</strong>
        : {{ $t("ADDITIONAL_INFORMATION_CONTENT") }}{{ $t("ADDITIONAL_INFORMATION_CONTENT") }}
        <a :href="privacyLink" target="_blank">
          <span class="uppercase">{{ $t("HERE") }}</span>
        </a>
      </p>
      <v-checkbox
        class="mt-0 mb-0"
        v-model="value.privacyConsent"
        hide-details="auto"
        :rules="[requiredRule]"
      >
        <template v-slot:label>
          <p class="text-privacy">
            {{$t("PRIVACY_POLICY_CHECKBOX_START")}}
            <a 
              target="_blank"
              @click.stop
              :href="privacyLink">{{$t("PRIVACY_POLICY")}}</a>
            {{$t("PRIVACY_POLICY_CHECKBOX_END")}}
          </p>
        </template>
      </v-checkbox>
      <v-checkbox class="mt-0 mb-0" v-model="value.promoConsent" hide-details="auto">
        <template v-slot:label>
          <p class="text-privacy">
            {{$t("PROMO_CHECKBOX")}}
          </p>
        </template>
      </v-checkbox>
    </div>
  </v-form>
</template>

<script>
require('@/assets/docs/Privacy_Policy_EN.pdf')
require('@/assets/docs/Privacy_Policy_ES.pdf')
export default {
  name: "SignupForm",
  data() {
    return {
      valid: true,
      passwordVisible: false,
      repeatPasswordVisible: false,
      fieldsFocused: [false, false],
    }
  },
  computed: {
    administrationString() {
      return "administration"
    },
    schoolAdministratorString() {
      return this.$t("SCHOOL_ADMINISTRATOR")
    },
    teacherString() {
      return this.$t("TEACHER")
    },
    teacherLowString() {
      return "teacher"
    },
    nameString() {
      return this.$t("NAME")
    },
    surnameString() {
      return this.$t("SURNAME")
    },
    emailString() {
      return this.$t("EMAIL")
    },
    confirmEmailAddressString() {
      return this.$t("CONFIRM_EMAIL_ADDRESS")
    },
    passwordString() {
      return this.$t("PASSWORD")
    },
    confirmPasswordString() {
      return this.$t("CONFIRM_PASSWORD")
    },
    phoneNumberString() {
      return this.$t("PHONE")
    },
    privacyLink() {
      return this.$t("PRIVACY_LINK")
    },
    rightsEmail() {
      return this.$t("RIGHTS_EMAIL")
    },
  },
  watch: {
    valid(val) {
      this.$emit("validationChanged", val)
    },
  },
  methods: {
    requiredRule(value) {
      return !!value || "Required."
    },
    emailRule(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || "Invalid email."
    },
    emailMatchesRule(val) {
      return val === this.value.email || "Email doesn't match"
    },
    passwordMatchesRule(val) {
      return val === this.value.password || "Password doesn't match"
    },
    numericOnlyRule(value) {
      const pattern = /^[+]*[0-9]*$/
      return pattern.test(value) || "Invalid phone number"
    },
  },
  props: {
    value: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.subscribe-input-field {
  margin-bottom: 5px !important;
}
.text-privacy {
  font-size: 12px;
}
.uppercase {
  text-transform: uppercase;
}

a {
  color: $primary !important;
  transition: color 0.2s;
  text-decoration: none;
}
</style>
