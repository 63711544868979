export function getLangFlag(langCode) {
  switch (langCode) {
    case "it":
      return require("@/assets/flags/ITA.svg")
    case "en":
      return require("@/assets/flags/UK.svg")
    case "es":
      return require("@/assets/flags/SPA.svg")
    case "fr":
      return require("@/assets/flags/FRA.svg")
    default:
      return require("@/assets/icons/countries.svg")
  }
}

// Returns a string from a date as MM/DD/YYYY
export function dateToString(d) {
  let month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()

  return `${day}/${month}/${d.getFullYear()}`
}

// Returns a string from a date as HH:MM
export function dateToStringTime(d) {
  let hour = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
  let minute = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()

  return `${hour}:${minute}`
}
