<template>
  <div :class="['dashboard-card-container', disabled && 'disabled']">
    <div
      :class="[headerColor, sticky && 'sticky', 'header-container']"
      :style="{ height: headerHeight }"
    >
      <div class="header-prepend"><slot name="header-prepend" /></div>
      <div
        :class="[
          'header-title',
          'text-bold',
          small ? 'text-body' : 'text-title',
        ]"
      >
        {{ title }}
      </div>
      <div class="header-append"><slot name="header-append" /></div>
    </div>

    <div :ref="`dashboardCard-${title}-content`" class="content-container">
      <slot v-if="showContent" name="content" />
    </div>

    <div :ref="`dashboardCard-${title}-footer`" class="footer">
      <slot v-if="showContent" name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OHDashboardCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    headerColor: {
      type: String,
      default: "medium-grey",
    },
    headerHeight: {
      type: String,
      default: "50px",
    },
    small: {
      type: Boolean,
      default: false,
    },
    // Stycky Header
    sticky: {
      type: Boolean,
      default: true,
    },
    // True to disable all the elements into thee div
    disabled: {
      type: Boolean,
      default: false,
    },
    // False to hide the content and the footer, used in school project when switch is false
    showContent: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    calculateClasses() {
      // If the footer is defined add the padded class to the footer and the bottom margin to the content
      if (
        this.$slots.footer &&
        this.$refs[`dashboardCard-${this.title}-footer`] &&
        this.$refs[`dashboardCard-${this.title}-content`]
      ) {
        if (this.showContent) {
          this.$refs[`dashboardCard-${this.title}-footer`].classList.remove(
            "hided"
          )
          const footerHeight =
            this.$refs[`dashboardCard-${this.title}-footer`].offsetHeight
          this.$refs[`dashboardCard-${this.title}-content`].style = {}
          this.$refs[
            `dashboardCard-${this.title}-content`
          ].style.paddingBottom = `${footerHeight + 10}px`
        } else {
          this.$refs[`dashboardCard-${this.title}-footer`].classList.add(
            "hided"
          )
          this.$refs[
            `dashboardCard-${this.title}-content`
          ].style.padding = `0px 0px 0px 0px`
        }

        this.$refs[`dashboardCard-${this.title}-footer`].classList.add("padded")
      }
    },
  },
  watch: {
    showContent() {
      this.calculateClasses()
    },
  },
  mounted() {
    this.calculateClasses()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles";

.dashboard-card-container {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: $shadow;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.dashboard-card-container.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.dashboard-card-container > .header-container {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0px 20px 0px 20px;
  font-size: 120%;
  font-weight: bold;
  color: white;
  z-index: 2;
}

.dashboard-card-container > .header-container.sticky {
  position: sticky;
  top: 0;
}

.dashboard-card-container > .content-container {
  flex: 1 0 auto;
  padding: 12px 20px 12px 20px;
  z-index: 1;
}

.dashboard-card-container > .content-container.hided {
  padding: 0 !important;
}

.dashboard-card-container > .footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 2;
}
.dashboard-card-container > .footer.padded {
  padding: 24px 20px 18px 20px;
}

.dashboard-card-container > .footer.hided.padded {
  padding: 0 !important;
}

.dashboard-card-container > .header-container > .header-title {
  flex: 1 0 auto;
}

.dashboard-card-container > .header-container > .header-prepend,
.dashboard-card-container > .header-container > .header-append {
  flex: 0 1 auto;
  align-items: center;
  display: flex;
}
</style>
