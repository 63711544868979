<template>
  <v-container class="fill-height" fluid style="justify-content: center">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10" lg="8">
        <v-card class="signup-container">
          <v-row justify="start">
            <v-col sm="3" md="3" lg="3">
              <v-img
                :src="require('@/assets/lr-logo.svg')"
                contain
                height="60px"
              />
            </v-col>
            <v-col
              sm="8"
              md="8"
              lg="8"
              class="flex-column align-start justify-start mt-2"
            >
              <p class="mb-0">{{ $t("WELCOME_SIGNUP") }}</p>
              <p class="subtitle" v-if="pass === 1">
                {{ $t("SELECT_COUNTRY_SIGNUP") }}
              </p>
              <p class="subtitle" v-if="pass === 2">
                {{ $t("SELECT_SCHOOL") }}
              </p>
              <p class="subtitle" v-if="pass === 3">
                {{ $t("SELECT_SUBJECT_SIGNUP") }}
              </p>
            </v-col>
            <v-col class="mt-2 d-flex justify-end" sm="1" md="1" lg="1">
              <router-link to="/login" class="login-text">{{
                $t("LOGIN")
              }}</router-link>
            </v-col>
          </v-row>

          <!-- Stepper Start -->
          <v-stepper v-model="pass" class="oh-stepper">
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row justify="start">
                  <v-col sm="6">
                    <OHList
                      showFlag
                      :title="countryString"
                      :items="countries"
                      v-model="selectedCountry"
                      @select="onSelectCountry($event)"
                    />
                  </v-col>
                  <v-col sm="6">
                    <OHList
                      showFlag
                      :title="languageString"
                      :items="languages"
                      v-model="selectedLanguage"
                      @select="onSelectLanguage($event)"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <SelectSchool @handleSelectSchool="onSelectSchool" :flagReset="pass" />
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-row>
                  <v-col sm="6">
                    <div class="subjects-list">
                      <p class="subjects-list__title">
                        {{ $t("SCHOOL_SUBJECT") }}
                      </p>
                      <div class="subjects-list__content">
                        <OHList
                          :items="subjects"
                          @select="onSelectSubject($event)"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col sm="6">
                    <div class="subscriber-info">
                      <signup-form
                        v-model="subscriberInfo"
                        @validationChanged="validForm"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <v-row justify="center">
            <v-col></v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                :disabled="pass < 2"
                @click="backStep()"
                color="primary"
                outlined
                class="text-capitalize pl-6 pr-6 mx-1 signup-button"
                rounded
                block
                >{{ $t("BACK") }}</v-btn
              >
              <v-btn
                color="primary"
                :disabled="disableNext()"
                class="text-capitalize pl-6 pr-6 mx-1 signup-button"
                @click="nextStep()"
                rounded
                block
              >
                <span v-if="pass === 3">{{ $t("SIGN_UP") }}</span>
                <span v-else>{{ $t("NEXT") }}</span>
              </v-btn>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="12">
        <v-row justify="center">
          <CopyrightAmplifon />
        </v-row>
      </v-col>
    </v-row>
    <v-fade-transition>
      <OKAlert
        v-if="alertErrorVisible"
        :title="$t('ERROR')"
        :message="!!error ? error.message : ''"
        dismissable
        :dismissAction="dismissAlertError"
      />
    </v-fade-transition>
  </v-container>
</template>

<script>
import SignupForm from "../components/SignupForm.vue"
import SelectSchool from "../components/SelectSchool.vue"
import { mapState, mapActions, mapMutations } from "vuex"

import {
  GET_COUNTRIES,
  GET_SCHOOLS,
  GET_SUBJECTS,
  SIGNUP,
} from "@/store/actions"
import { EMPTY_SCHOOL } from "../store/mutations"

export default {
  components: { SignupForm, SelectSchool },
  name: "Signup",
  data() {
    return {
      alertErrorVisible: false,
      doubleEmailError: false,
      pass: 1,
      selectedLanguage: {},
      selectedCountry: {},
      selectedSchool: null,
      selectedSubject: null,
      subscriberInfo: {
        role: "",
        name: "",
        surname: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        privacyConsent:false,
        promoConsent:false
      },
      formIsValid: false,
      languages: [],
    }
  },
  computed: {
    ...mapState({
      schools: (state) => state.schools.schools,
      countries: (state) => state.schools.countries,
      subjects: (state) => state.schools.subjects,
      error: (state) => state.session.error,
    }),
    signupParams() {
      return {
        firstName: this.subscriberInfo.name,
        lastName: this.subscriberInfo.surname,
        password: this.subscriberInfo.password,
        email: this.subscriberInfo.email,
        phone: this.subscriberInfo.phoneNumber,
        countryCode: this.selectedCountry.countryCode,
        language: this.selectedLanguage.id,
        schoolId: this.selectedSchool,
        position: this.subscriberInfo.role.toLowerCase(),
        subjectId: this.selectedSubject.subjectId,
        privacyConsent: this.selectedSubject.privacyConsent,
        promoConsent: this.selectedSubject.promoConsent
      }
    },
    countryString() {
      return this.$t("COUNTRY")
    },
    languageString() {
      return this.$t("LANGUAGE")
    },
  },
  watch: {
    error(newValue) {
      if (newValue != null) {
        this.alertErrorVisible = true
      }
    },
    pass: {
      handler() {
        this.fetchApiByStep()
      },
    },
    selectedCountry(val) {
      this.selectedLanguage = {}
      if ("countryCode" in val) {
        this.languages = this.countries.find(
          (c) => c.countryCode == val.countryCode
        ).languages
      }
    },
  },
  methods: {
    ...mapActions([GET_COUNTRIES, GET_SCHOOLS, GET_SUBJECTS, SIGNUP]),
    ...mapMutations([EMPTY_SCHOOL]),
    async fetchApiByStep() {
      switch (this.pass) {
        case 1: 
          this.EMPTY_SCHOOL()
        case 2: 
          await this.getSchools()  
          break
        case 3:
          await this.getSubjects()
          break
        default:
          break
      }
    },
    dismissAlertError() {
      this.alertErrorVisible = false
    },
    validForm(val) {
      this.formIsValid = val
    },
    async signupAction() {
      try {
        await this.signUp()
        !this.doubleEmailError && this.$router.push("/thank-you")
        this.doubleEmailError = false
      } catch (e) {
        console.log(e)
        this.doubleEmailError = false
      }
    },
    nextStep() {
      if (this.pass === 1 || this.pass == 2) return this.pass++
      else this.signupAction()
    },
    disableNext() {
      if (
        (this.pass === 1 &&
          (!this.selectedLanguage.id || !this.selectedCountry.countryCode)) ||
        (this.pass === 2 && this.selectedSchool === null) ||
        (this.pass === 3 && !this.formIsValid)
      ) {
        return true
      } else return false
    },
    backStep() {
      if (this.pass === 2 || this.pass == 3) return this.pass--
    },
    onSelectLanguage(language) {
      this.selectedLanguage = language
      switch (this.selectedLanguage.id) {
        case "es": 
          this.$i18n.locale = "es"
          break;
        case "en": 
          this.$i18n.locale = "en"
          break;
        case "it": 
          this.$i18n.locale = "it"
          break;

        default:
          this.$i18n.locale = "es"
          break;
      }
    },
    onSelectCountry(country) {
      this.selectedCountry = country
      this.$store.state.schools.selectedCountry = country.countryCode // to refactor
    },
    onSelectSubject(subject) {
      this.selectedSubject = subject
    },
    onSelectSchool(schoolId) {
      this.selectedSchool = schoolId
    },
    async getCountries(lang) {
      try {
        await this.GET_COUNTRIES(lang)
      } catch (e) {
        console.log(e)
      }
      this.selectedCountry = this.countries[0]
      this.$store.state.schools.selectedCountry = this.countries[0].countryCode
    },
    async getSchools() {
      try {
        await this.GET_SCHOOLS({
          countryCode: this.selectedCountry.countryCode,
          searchString: "",
          postalCode: "",
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getSubjects() {
      try {
        await this.GET_SUBJECTS({
          countryCode: this.selectedCountry.countryCode,
          language: this.selectedLanguage.id,
        })
      } catch (e) {
        console.log(e)
      }
    },
    async signUp() {
      try {
        await this.SIGNUP(this.signupParams)
      } catch (e) {
        console.log(e)
        this.doubleEmailError = true
      }
    },
  },
  async beforeMount() {
    this.getCountries(navigator.language.substring(0, 2))
  },
}
</script>

<style lang="scss" scoped>
@import "../styles";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.signup-container {
  padding: 32.5px 22px 67px;
  border-radius: $border-radius !important;
  box-shadow: $shadow;
  background-color: $background;
}
.signup-button {
  text-transform: capitalize;
  padding: 12px 48px 12px 48px !important;
  font-weight: bold;
  font-size: 21px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: $secondary-font;
}

.oh-stepper {
  background-color: $background !important;
  margin-bottom: 5px;
  box-shadow: none !important;
}

.subtitle {
  font-family: $secondary-font;
  font-weight: bold;
  color: $primary;
  font-size: 21px;
}

.copy-text {
  font-family: "Poppins" !important;
}
.login-text {
  text-decoration: underline;
}

.subjects-list {
  min-height: 25rem;
  margin-top: 20px;
  &__title {
    margin-bottom: 22px;
  }
  &__content {
    max-height: 41rem;
    overflow: scroll;
  }
}
.subscriber-info {
  min-height: 25rem;
}
</style>
