// For the passed type, create three mutations
export const createAsyncMutation = (type) => ({
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  REQUEST: `${type}_REQUEST`,
})

// Authentication
export const LOGIN = createAsyncMutation("LOGIN")
export const LOGOUT = "LOGOUT"
export const GET_CONFIGURATIONS_MUTATION =
  createAsyncMutation("GET_CONFIGURATIONS")
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY"

export const AUTH_STANDARD_MUTATION = createAsyncMutation(
  "AUTH_STANDARD_MUTATION"
)
export const SIGNUP_MUTATION = createAsyncMutation("SIGNUP_MUTATION")
export const CHECK_SIGNUP_CODE_MUTATION = createAsyncMutation(
  "CHECK_SIGNUP_CODE_MUTATION"
)
export const RESEND_SIGNUP_EMAIL_MUTATION = createAsyncMutation(
  "RESEND_SIGNUP_EMAIL_MUTATION"
)
export const CHANGE_MY_PASSWORD_MUTATION = createAsyncMutation(
  "CHANGE_MY_PASSWORD_MUTATION"
)
export const GET_TEACHER_INFO_MUTATION = createAsyncMutation(
  "GET_TEACHER_INFO_MUTATION"
)

export const CHECK_RESET_PASSWORD_CODE_MUTATION = createAsyncMutation(
  "CHECK_RESET_PASSWORD_CODE_MUTATION"
)

// Contents
export const GET_GLOBAL_CONTENTS_MUTATION = createAsyncMutation(
  "GET_GLOBAL_CONTENTS"
)
export const GET_COUNTRY_CONTENTS_MUTATION = createAsyncMutation(
  "GET_COUNTRY_CONTENTS"
)
export const UPDATE_AMPLIFON_INFO_MUTATION = createAsyncMutation(
  "UPDATE_AMPLIFON_INFO"
)
export const UPDATE_INFORMATIVE_MUTATION =
  createAsyncMutation("UPDATE_INFORMATIVE")
export const UPDATE_PRIVACY_INFO_MUTATION = createAsyncMutation(
  "UPDATE_PRIVACY_INFO"
)
export const UPDATE_PRIVACY_FILE_MUTATION = createAsyncMutation(
  "UPDATE_PRIVACY_FILE"
)
export const ADD_QUESTION_MUTATION = createAsyncMutation("ADD_QUESTION")
export const UPDATE_QUESTION_MUTATION = createAsyncMutation("UPDATE_QUESTION")
export const DELETE_QUESTION_MUTATION = createAsyncMutation("DELETE_QUESTION")
export const GET_STATS_MUTATION = createAsyncMutation("GET_STATS")

// News
export const GET_NEWS_MUTATION = createAsyncMutation("GET_NEWS")
export const GET_NEWS_DETAIL_MUTATION = createAsyncMutation("GET_NEWS_DETAIL")
export const ADD_NEWS_MUTATION = createAsyncMutation("ADD_NEWS")
export const ADD_NEWS_MEDIA_MUTATION = createAsyncMutation("ADD_NEWS_MEDIA")
export const EDIT_NEWS_MUTATION = createAsyncMutation("EDIT_NEWS")
export const DELETE_NEWS_MUTATION = createAsyncMutation("DELETE_NEWS")
export const DELETE_NEWS_MEDIA_MUTATION =
  createAsyncMutation("DELETE_NEWS_MEDIA")
export const CHANGE_NEWS_ORDER_MUTATION =
  createAsyncMutation("CHANGE_NEWS_ORDER")
export const SET_NEWS_DETAIL = "SET_NEWS_DETAIL"
export const SORT_NEWS = "SORT_NEWS"
export const FILTER_NEWS = "FILTER_NEWS"

// School News
export const GET_SCHOOL_NEWS_MUTATION = createAsyncMutation("GET_SCHOOL_NEWS")
export const EDIT_SCHOOL_NEWS_MUTATION = createAsyncMutation("EDIT_SCHOOL_NEWS")
export const REFRESH_SCHOOL_NEWS_MUTATION = createAsyncMutation(
  "REFRESH_SCHOOL_NEWS"
)
export const CHANGE_SCHOOL_NEWS_ORDER_MUTATION = createAsyncMutation(
  "CHANGE_SCHOOL_NEWS_ORDER"
)
export const SORT_SCHOOL_NEWS = "SORT_SCHOOL_NEWS"
export const FILTER_SCHOOL_NEWS = "FILTER_SCHOOL_NEWS"

// Maps
export const GET_HEATMAP_MUTATION = createAsyncMutation("GET_HEATMAP_MUTATION")
export const GET_PLACES_MUTATION = createAsyncMutation("GET_PLACES_MUTATION")
export const SEARCH_PLACES_MUTATION = createAsyncMutation(
  "SEARCH_PLACES_MUTATION"
)
export const GET_CATEGORIES_MUTATION = createAsyncMutation(
  "GET_CATEGORIES_MUTATION"
)

// Utils
export const SORT = "SORT"
export const FILTER = "FILTER"
export const CLEAR_SESSION_MESSAGES = "CLEAR_SESSION_MESSAGES"
export const SET_CAN_CHANGE_PAGE = "SET_CAN_CHANGE_PAGE"
export const GET_LOGS_MUTATION = createAsyncMutation("GET_LOGS")
export const SORT_LOGS = "SORT_LOGS"
export const FILTER_LOGS = "FILTER_LOGS"
export const SET_ACTION_TO_PERFORM_ON_PROCEED =
  "SET_ACTION_TO_PERFORM_ON_PROCEED"
export const SET_ACTION_TO_PERFORM_AFTER_PROCEED =
  "SET_ACTION_TO_PERFORM_AFTER_PROCEED"
export const PERFORM_ON_PROCEED = "PERFORM_ON_PROCEED"
export const SET_SESSION_MESSAGE = "SET_SESSION_MESSAGE"

// UI
export const TOGGLE_DRAWER = "TOGGLE_DRAWER"
export const TOGGLE_OVERLAY = "TOGGLE_OVERLAY"
export const TOGGLE_PROFILE = "TOGGLE_PROFILE"

// Leaderboard
export const GET_SCHOOL_RANKINGS_MUTATION = createAsyncMutation(
  "GET_SCHOOL_RANKINGS_MUTATION"
)
export const SORT_SCHOOL_RANKINGS = "SORT_SCHOOL_RANKINGS"
export const FILTER_SCHOOL_RANKINGS = "FILTER_SCHOOL_RANKINGS"

// School Project
export const GET_SCHOOL_PROJECT_CONTENTS_MUTATION = createAsyncMutation(
  "GET_SCHOOL_PROJECT_CONTENTS"
)
export const UPDATE_SCHOOL_PROJECT_STATE_MUTATION = createAsyncMutation(
  "UPDATE_SCHOOL_PROJECT_STATE"
)
export const UPDATE_SCHOOL_PROJECT_MUTATION = createAsyncMutation(
  "UPDATE_SCHOOL_PROJECT"
)

// Schools
export const GET_COUNTRIES_MUTATION = createAsyncMutation(
  "GET_COUNTRIES_MUTATION"
)
export const GET_SCHOOLS_MUTATION = createAsyncMutation("GET_SCHOOLS_MUTATION")
export const GET_SCHOOLS_SCROLL_MUTATION = createAsyncMutation(
  "GET_SCHOOLS_SCROLL_MUTATION"
)
export const GET_SUBJECTS_MUTATION = createAsyncMutation(
  "GET_SUBJECTS_MUTATION"
)
export const GET_STUDENT_CODES_MUTATION = createAsyncMutation(
  "GET_STUDENT_CODES_MUTATION"
)
export const ADD_STUDENT_CODE_MUTATION = createAsyncMutation(
  "ADD_STUDENT_CODE_MUTATION"
)

export const EMPTY_SCHOOL = "EMPTY_SCHOOL"


