<template>
  <v-card :class="[!borderless ? 'ok-card' : 'ok-card-borderless']">
    <v-container class="cc">
      <!-- Header -->
      <v-row
        :class="[
          'flex-grow-0 flex-shrink-0 align-center justify-space-between',
          fixedHeader && 'ok-card-fixed-header',
        ]"
        style="margin-top: 12px"
        no-gutters
      >
        <v-col
          cols="auto"
          class="card-title font-weight-bold d-flex align-center"
          align-self="center"
        >
          <v-img
            v-if="draggable"
            :src="require('@/assets/icons/drag-icon.svg')"
            contain
            height="30"
            class="mr-2"
          />
          {{ title }}
        </v-col>
        <v-col
          cols="auto"
          :class="[
            close && 'mr-4',
            'd-flex',
            'align-center flex-grow-1 justify-end',
          ]"
        >
          <slot name="topActions" />
          <v-btn v-if="refresh" text icon color="primary" @click="!!refresh()">
            <img
              :src="require('@/assets/icons/refresh.svg')"
              height="20"
              contain
            />
          </v-btn>

          <v-btn v-if="collapsable" text icon @click="collapse = !collapse">
            <v-icon
              color="primary"
              :class="['collapse-icon', collapse && 'collapse-icon-closed']"
              >fas fa-caret-up</v-icon
            >
          </v-btn>

          <v-btn v-if="close" text icon @click="!!close()" id="dismiss-icon">
            <img
              :src="require('@/assets/icons/dismiss.svg')"
              height="28"
              contain
            />
          </v-btn>
        </v-col>
      </v-row>

      <!-- Content -->
      <v-expand-transition>
        <v-row
          v-show="!collapse"
          :class="[
            'flex-grow-1 flex-shrink-0',
            fixedHeader && 'mt-6',
            'content-row',
          ]"
        >
          <slot name="content" />
        </v-row>
      </v-expand-transition>

      <!-- Footer -->
      <v-row class="ok-card-footer">
        <slot name="footer" />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "OKCard",
  props: {
    // Title of the card
    title: {
      type: String,
    },
    // If exists, add a button to refresh and connect this action to click
    refresh: {
      type: Function,
    },
    // If exists, add a button to close and connect this action to click
    close: {
      type: Function,
    },
    // If true, add the drag iconto the left of the title
    draggable: {
      type: Boolean,
      default: false,
    },
    // If true, remove the custom borders of the container
    borderless: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapse: false,
    }
  },
}
</script>

<style lang="scss">
@import "@/styles";

.ok-card {
  box-shadow: $shadow;
  border-radius: $border-radius !important;
}

.ok-card .card-title,
.ok-card-borderless .card-title {
  text-transform: uppercase;
}

.ok-card-borderless {
  padding-top: 0px;
  box-shadow: 0px 0px 17px 1px rgba(44, 40, 40, 0.1);
}

.ok-card-fixed-header {
  position: sticky;
  background-color: $background !important;
  top: 0;
  z-index: 10;
  font-size: 1.3rem;
  padding-top: 8px;
}

.cc {
  position: relative;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding-top: 0px;
  max-width: none !important;
}
.ok-card-footer {
  position: sticky;
  bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
}

.detail-card .content-row {
  max-height: 75vh !important;
  overflow-y: auto;
}

.detail-card .ok-card-footer {
  position: relative;
  bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: $background;
  z-index: 2;
}
.ok-footer-solid {
  box-shadow: 0px 0px 17px 1px rgba(44, 40, 40, 0.1);
  background-color: white;
}

.collapse-icon {
  transition: all 0.2s;
}
.collapse-icon-closed {
  transform: rotate(-180deg);
}
#dismiss-icon {
  position: absolute;
  top: 18px;
  right: 8px;
}
</style>
