<template>
  <div>
    <span class="list-title">{{ this.title }}</span>
    <v-card>
      <v-list>
        <div v-for="(item, index) in items" :key="item.label">
          <v-list-item @click="onSelectItem(item)">
            <v-list-item-avatar v-if="showFlag">
              <v-img
                :src="
                  item.countryCode
                    ? require(`@/assets/flags/${item.countryCode}.svg`)
                    : getLangFlag(item.id)
                "
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title class="pb-1" v-bind="attrs" v-on="on">{{ item.label }}</v-list-item-title>
                </template>
                 <span>{{ item.label }}</span>
              </v-tooltip>
            </v-list-item-content>
            <span>
              <v-img
                v-if="item.label === val"
                :src="require('@/assets/icons/check.svg')"
                width="17px"
                height="17px"
              ></v-img>
            </span>
          </v-list-item>
          <v-divider v-if="index < items.length - 1"></v-divider>
        </div>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import { getLangFlag } from "@/utils"
export default {
  name: "OHList",
  props: {
    value: Object,
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    showFlag: Boolean,
  },
  data() {
    return {
      getLangFlag: getLangFlag,
      val: this.value?.label,
    }
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.val = this.value?.label
      },
    },
  },
  methods: {
    onSelectItem(item) {
      this.val = item.label
      this.$emit("select", item)
    },
  },
}
</script>

<style lang="scss" scoped>
.list-title {
  font-family: Value;
  font-size: 21px;
  color: $light-grey;
}
</style>
