<template>
  <div>
    <toolbar />
    <v-row class="ml-1 mr-1">
      <v-col sm="1" v-if="$vuetify.breakpoint.name != 'xs'"></v-col>
      <v-col sm="10">
        <v-row>
          <v-col sm="12" md="8">
            <div class="main-component">
              <v-container>
                <v-row v-if="$vuetify.breakpoint.name == 'xs'">
                  <div class>
                    <v-text-field
                      dense
                      class="main-text-input"
                      style="margin-bottom: 8px"
                      :label="searchString"
                      v-model="classFilter"
                      outlined
                      hide-details="true"
                    ></v-text-field>

                    <v-text-field
                      dense
                      class="main-text-input"
                      :label="schoolYearString"
                      v-model="schoolYearFilter"
                      outlined
                      hide-details="true"
                    ></v-text-field>
                  </div>
                </v-row>
                <v-row v-else>
                  <v-col sm="7" md="8">
                    <v-text-field
                      dense
                      class="main-text-input"
                      :label="searchString"
                      v-model="classFilter"
                      outlined
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="5" md="4">
                    <v-text-field
                      dense
                      class="main-text-input"
                      :label="schoolYearString"
                      v-model="schoolYearFilter"
                      outlined
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
          <v-col sm="12" md="4">
            <div
              :class="
                !['md', 'sm'].includes($vuetify.breakpoint.name)
                  ? 'space-between-div'
                  : 'center-div'
              "
            >
              <div
                v-if="!['md', 'sm'].includes($vuetify.breakpoint.name)"
              ></div>
              <div>
                <v-btn
                  color="primary"
                  class="text-capitalize pl-6 pr-6 mx-1 mt-10 generic-button"
                  @click="isCreateClass = !isCreateClass"
                  rounded
                  block
                  >{{ $t("CREATE_CLASS") }}</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.name != 'xs'" sm="1"></v-col>
    </v-row>

    <v-row>
      <v-col v-if="$vuetify.breakpoint.name != 'xs'" sm="1">
        <div class></div>
      </v-col>
      <v-col cols="12" xs="12" sm="10">
        <div
          style="
            padding-left: 14px;
            padding-right: 14px;
            width: 100%;
            display: flex;
            justify-content: center;
          "
        >
          <OHDashboardCard
            :title="classesListString"
            :style="{
              flex: '1 1 100%',
              overflowY: 'auto',
              'margin-top': '14px',
            }"
            :disabled="false"
          >
            <template v-slot:content>
              <!-- table start -->
              <v-container fluid class="pl-0 pr-0" style="overflow: hidden">
                <v-row>
                  <v-col
                    v-if="$vuetify.breakpoint.name != 'xs'"
                    class="pl-0 pr-0 mb-4"
                    cols="12"
                  >
                    <table
                      id="okTable"
                      class="noselect"
                      v-if="$vuetify.breakpoint.name != 'xs'"
                      :style="{
                        tableLayout:
                          $vuetify.breakpoint.width < 1264 ? 'fixed' : 'auto',
                      }"
                    >
                      <thead>
                        <tr>
                          <th v-for="header in fullHeaders" :key="header.text">
                            <div
                              class="d-flex align-center noselect"
                              @click="handleSort(header.key)"
                            >
                              <v-img
                                v-if="header.sortable"
                                :src="getSortingIcon(header.key)"
                                height="15"
                                class="sort-icon"
                                contain
                              />
                              <span>{{ header.text.toUpperCase() }}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tr v-for="(element, index) in sortedCodes" :key="index">
                        <td>{{ element.className }}</td>
                        <td>{{ element.schoolYear }}</td>
                        <td>{{ dateToString(element.dateCreated) }}</td>
                        <td>{{ element.numMeasures }}</td>
                        <td>{{ element.usages }}</td>
                        <td>
                          <div class="space-between-div align-center">
                            <div>{{ element.code }}</div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  style="padding-right: 20px"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-img
                                    style="cursor: pointer"
                                    :src="require('@/assets/icons/copy.svg')"
                                    contain
                                    height="18"
                                    v-clipboard:copy="element.code"
                                  />
                                </div>
                              </template>
                              <span>{{ $t("COPY_TO_CLIPBOARD") }}</span>
                            </v-tooltip>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                  <v-col v-else xs="12">
                    <v-container
                      v-for="(element, index) in sortedCodes"
                      :key="index"
                      style="padding: 5px"
                    >
                      <div class="mobile-row">
                        <div class="mobile-card-title" style="width: 33.33%">
                          {{
                            fullHeaders.find((e) => e.key == "className").text
                          }}
                        </div>
                        <div style="width: 66.66%">{{ element.className }}</div>
                      </div>
                      <div class="mobile-row__odd">
                        <div class="mobile-card-title" style="width: 33.33%">
                          {{
                            fullHeaders.find((e) => e.key == "schoolYear").text
                          }}
                        </div>
                        <div style="width: 66.66%">
                          {{ element.schoolYear }}
                        </div>
                      </div>

                      <div class="mobile-row">
                        <div class="mobile-card-title" style="width: 33.33%">
                          {{
                            fullHeaders.find((e) => e.key == "dateCreated").text
                          }}
                        </div>
                        <div style="width: 66.66%">
                          {{ dateToString(element.dateCreated) }}
                        </div>
                      </div>

                      <div class="mobile-row__odd">
                        <div class="mobile-card-title" style="width: 33.33%">
                          {{
                            fullHeaders.find((e) => e.key == "numMeasures").text
                          }}
                        </div>
                        <div style="width: 66.66%">
                          {{ element.numMeasures }}
                        </div>
                      </div>
                      <div class="mobile-row">
                        <div class="mobile-card-title" style="width: 33.33%">
                          {{ fullHeaders.find((e) => e.key == "usages").text }}
                        </div>
                        <div style="width: 66.66%">{{ element.usages }}</div>
                      </div>
                      <div class="mobile-row__odd">
                        <div class="mobile-card-title" style="width: 33.33%">
                          {{ fullHeaders.find((e) => e.key == "code").text }}
                        </div>
                        <div style="width: 66.66%">
                          <div class="space-between-div align-center">
                            <div>{{ element.code }}</div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  style="padding-right: 20px"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-img
                                    style="cursor: default !important"
                                    :src="require('@/assets/icons/copy.svg')"
                                    contain
                                    height="18"
                                    v-clipboard:copy="element.code"
                                  />
                                </div>
                              </template>
                              <span>{{ $t("COPY_TO_CLIPBOARD") }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>

                      <v-divider style="margin-top: 6px"></v-divider>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
              <!-- table end -->
            </template>
          </OHDashboardCard>
        </div>
      </v-col>
      <v-col sm="1">
        <div v-if="$vuetify.breakpoint.name != 'xs'" class></div>
      </v-col>
    </v-row>

    <v-dialog v-model="isCreateClass" width="1000" :scrollable="true">
      <v-card class="popup-relative">
        <img
          src="@/assets/icons/dismiss.svg"
          alt
          class="close-icon"
          @click="isCreateClass = false"
        />
        <v-card-title class="text-h5 lighten-2 mt-3 ml-5">{{
          $t("CREATE_CLASS")
        }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <div class="main-component">
                <v-row>
                  <v-col sm="8">
                    <v-text-field
                      class="main-text-input"
                      :label="classNameString"
                      v-model="createClassName"
                      outlined
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="4">
                    <v-text-field
                      class="main-text-input"
                      :label="schoolYearString"
                      v-model="createSchoolYear"
                      disabled
                      outlined
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12">
                    <p>{{ $t("CLASS_CODE_EXPLANATION") }}</p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col sm="4"></v-col>
            <v-col sm="4">
              <v-btn
                color="primary"
                @click="onCreateClass"
                class="text-capitalize pl-6 pr-6 mx-1 my-5 welcome-button"
                rounded
                block
                >{{ $t("CREATE_CLASS") }}</v-btn
              >
            </v-col>
            <v-col sm="4"></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <OKAlert
      v-show="alertErrorAddCodeClass"
      :title="$t('ERROR')"
      :message="$t('CREATE_CLASS_ERROR')"
      dismissable
      :dismissAction="
        () => {
          alertErrorAddCodeClass = false
        }
      "
    />
    <Footer />
  </div>
</template>

<script>
import { FILTER_TEXT, FILTER_DATE, FILTER_NUMBER } from "../utils/constants"
import { dateToString as dts, dateToStringTime as dtst } from "@/utils"
import { mapState, mapActions, mapGetters } from "vuex"
import {
  GET_STUDENT_CODES,
  ADD_STUDENT_CODE,
  GET_TEACHER_INFO,
} from "@/store/actions"

export default {
  name: "Welcome",
  data() {
    return {
      isCreateClass: false,
      alertErrorAddCodeClass: false,
      classFilter: "",
      schoolYearFilter: "",
      createClassName: "",
      createSchoolYear: "",
      sorting: {
        key: "",
        order: "asc",
      },
      fullHeaders: [
        {
          text: this.$t("CLASS_NAME"),
          value: "title",
          key: "className",
          type: FILTER_TEXT,
          sortable: false,
        },
        {
          text: this.$t("SCHOOL_YEAR"),
          value: "title",
          key: "schoolYear",
          type: FILTER_NUMBER,
          sortable: true,
        },
        {
          text: this.$t("CREATION_DATE"),
          value: "title",
          type: FILTER_DATE,
          key: "dateCreated",
          sortable: true,
        },
        {
          text: this.$t("NOISE_TRACKING"),
          value: "order",
          type: FILTER_NUMBER,
          key: "numMeasures",
          sortable: true,
        },
        {
          text: this.$t("USED"),
          value: "order",
          type: FILTER_NUMBER,
          key: "usages",
          sortable: true,
        },
        {
          text: this.$t("CLASS_CODE"),
          value: "title",
          key: "code",
          type: FILTER_TEXT,
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      codes: (state) => state.schools.codes,
      country: (state) => state.auth.user.userSettings.defaultCountry,
      schoolId: (state) => state.auth.teacherInfo.school.id,
      schoolYear: (state) => state.auth.teacherInfo.schoolYear,
    }),
    ...mapGetters({
      role: "role",
    }),
    filteredCodes() {
      let codes = this.codes.filter(
        (c) =>
          c.className.toLowerCase().includes(this.classFilter.toLowerCase()) ||
          c.code.toLowerCase().includes(this.classFilter.toLowerCase())
      )
      return this.schoolYearFilter
        ? codes.filter((c) =>
            this.dateToString(c.dateCreated)
              .slice(-4)
              .includes(this.schoolYearFilter)
          )
        : codes
    },
    sortedCodes() {
      if (this.sorting.key) {
        const codes = this.filteredCodes.sort(
          (a, b) => a[this.sorting.key] - b[this.sorting.key]
        )
        return this.sorting.order == "asc" ? codes : codes.reverse()
      } else return this.filteredCodes
    },
    isFilterDisabled() {
      return !(this.schoolYearFilter || this.classFilter)
    },
    searchString() {
      return this.$t("SEARCH")
    },
    schoolYearString() {
      return this.$t("SCHOOL_YEAR")
    },
    classesListString() {
      return this.$t("CLASSES_LIST")
    },
    classNameString() {
      return this.$t("CLASS_NAME")
    },
  },
  methods: {
    // Returns a string from a date as MM/DD/YYYY
    ...mapActions([GET_STUDENT_CODES, ADD_STUDENT_CODE, GET_TEACHER_INFO]),
    dateToString(d) {
      if (!d) {
        return "-"
      }
      return dts(new Date(d))
    },
    async onCreateClass() {
      this.isCreateClass = !this.isCreateClass
      await this.addCode()
      this.createClassName = ""
      this.createSchoolYear = this.schoolYear || ""
      await this.getCodes()
    },
    handleSort(key) {
      if (this.sorting.key == key) {
        if (this.sorting.order == "asc") {
          this.sorting.order = "desc"
        } else {
          this.sorting.key = ""
          this.sorting.order = "asc"
        }
      } else {
        this.sorting.key = key
        this.sorting.order = "asc"
      }
    },
    async addCode() {
      try {
        await this.ADD_STUDENT_CODE({
          countryCode: this.country,
          schoolId: this.schoolId,
          className: this.createClassName,
        })
      } catch (e) {
        this.alertErrorAddCodeClass = true
      }
    },

    // Returns a string from a date as HH:MM
    dateToStringTime(d) {
      if (!d) {
        return "-"
      }
      return dtst(Date(d))
    },
    async getCodes() {
      try {
        await this.GET_STUDENT_CODES(this.country)
      } catch (e) {
        console.log(e)
      }
    },
    async getTeacherInfo() {
      try {
        await this.GET_TEACHER_INFO()
      } catch (e) {
        console.log(e)
      }
    },
    getSortingIcon(key) {
      if (key == this.sorting.key) {
        if (!this.sorting.order) {
          return require("@/assets/icons/sort-table-col.svg")
        } else
          return this.sorting.order == "asc"
            ? require("@/assets/icons/down.svg")
            : require("@/assets/icons/up.svg")
      }
      return require("@/assets/icons/sort-table-col.svg")
    },
  },
  async beforeMount() {
    //to refactor after changeLanguage section
    const localDefault = JSON.parse(localStorage.getItem("user-info"))
    this.$i18n.locale = localDefault && localDefault.userSettings.language
    await this.getCodes()
    if (this.role === "Teacher") {
      await this.getTeacherInfo()
      if (this.schoolYear) {
        this.createSchoolYear = this.schoolYear
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.main-component {
  min-height: 100px;
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding-left: 1rem;
}

.main-text-input {
  border-radius: 10px;
  border: 1px solid #e6e7e9;
  margin: 0 2px;
}

.main-text-input__large {
  max-width: 70%;
}

.welcome-button {
  text-transform: capitalize;
  padding: 12px 48px 12px 48px !important;
  font-weight: bold;
  font-size: 21px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: $secondary-font;
}
.generic-button {
  text-transform: capitalize;
  padding: 12px 20px 10px 20px !important;
  font-weight: bold;
  font-size: 18px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: $secondary-font;
}
.center-div {
  display: flex;
  justify-content: center;
}
.space-between-div {
  display: flex;
  justify-content: space-between;
}
.popup-relative {
  position: relative;
}
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 22px;
  margin-right: 22px;
  cursor: pointer;
}

::v-deep .v-dialog {
  overflow-y: hidden !important;
}

.mobile-row {
  display: flex;
  margin-bottom: 3px;
  margin-top: 3px;
  &__odd {
    background-color: #f9f9f9;
    display: flex;
  }
}
.mobile-card-title {
  color: #888;
  font-weight: bold;
}
// style to refactor

#okTable .table-header {
  background-color: $background;
  width: 100%;
}

table#okTable,
#okTable th,
#okTable td {
  border: 0px;
  border-collapse: collapse;
  font-size: 14px;
}

table#okTable {
  width: 100%;
  table-layout: fixed;
}

#okTable th {
  font-size: 12px;
  color: $light-grey;
  font-weight: bold;
}

#okTable th,
#okTable td {
  padding: 15px;
}

#okTable th div {
  display: flex;
  align-content: center;
}

table#okTable tbody tr:hover {
  background-color: $light-grey;
  color: white;
}

table#okTable tr:nth-child(even) {
  background-color: $background;
}

table#okTable th {
  background-color: $background;
}

table#okTable tr th div * {
  cursor: pointer;
}

table#okTable .edit-label {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

table#okTable .edit-label.disabled-edit-label {
  color: $very-light-grey;
  text-decoration: underline;
  cursor: auto;
}

table#okTable .status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;
}

.sort-icon {
  flex: none;
  margin-right: 8px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#okPaginator .v-pagination__item,
#okPaginator .v-pagination__navigation {
  border-color: $primary;
  border-style: solid;
  box-shadow: none;
  color: $primary;
  font-weight: bold;
}

#okPaginator .v-pagination__item.v-pagination__item--active {
  border-color: $primary !important;
  border-style: solid;
  box-shadow: none;
  color: white;
}

#okPaginator .v-pagination__navigation {
  border-width: 0 !important;
}

#okPaginator .v-pagination__navigation i {
  color: $medium-grey !important;
}
</style>
