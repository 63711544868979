<template>
  <v-container class="fill-height" fluid style="justify-content: center">
    <v-row justify="center" class="text-center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="change-pwd-container">
          <v-img
            :src="require('@/assets/icons/lr-logo-mini.svg')"
            contain
            height="78"
          />
          <v-card-text class="pb-0">
            <div class="mb-6 mt-4">
              <strong v-if="!!token">{{ $t("RESET_PASSWORD") }}</strong>
              <strong v-else>{{ $t("RESET_PASSWORD") }}</strong>
              <br />
            </div>
            <v-form ref="form">
              <v-text-field
                v-model="oldPassword"
                :type="oldPasswordVisible ? 'text' : 'password'"
                :placeholder="oldPasswordString"
                hide-details="auto"
                minlength="3"
                class="oh-input-field"
                solo
              >
                <template slot="prepend-inner">
                  <img
                    :src="
                      require(`@/assets/icons/lock/${
                        fieldsFocused[0] ? 'lock-active' : 'lock'
                      }.svg`)
                    "
                    class="mr-2"
                    height="30"
                  />
                </template>

                <template slot="append">
                  <img
                    :src="
                      require(`@/assets/icons/${
                        this.oldPasswordVisible ? 'eye-block' : 'eye'
                      }/${this.fieldsFocused[0] ? 'eye-active' : 'eye'}.svg`)
                    "
                    class="textfield-icon"
                    :color="fieldsFocused[0] ? '#C5003E' : null"
                    @click="oldPasswordVisible = !oldPasswordVisible"
                    style="height: 20px; cursor: pointer"
                  />
                </template>
              </v-text-field>
              <v-text-field
                v-model="newPassword"
                :type="newPasswordVisible ? 'text' : 'password'"
                :rules="[matchPassword]"
                :placeholder="newPasswordPlaceString"
                hide-details="auto"
                minlength="3"
                class="oh-input-field mt-2 mb-2"
                solo
              >
                <template slot="prepend-inner">
                  <img
                    :src="
                      require(`@/assets/icons/lock/${
                        fieldsFocused[1] ? 'lock-active' : 'lock'
                      }.svg`)
                    "
                    class="mr-2"
                    height="30"
                  />
                </template>

                <template slot="append">
                  <img
                    :src="
                      require(`@/assets/icons/${
                        this.newPasswordVisible ? 'eye-block' : 'eye'
                      }/${this.fieldsFocused[1] ? 'eye-active' : 'eye'}.svg`)
                    "
                    class="textfield-icon"
                    :color="fieldsFocused[1] ? '#C5003E' : null"
                    @click="newPasswordVisible = !newPasswordVisible"
                    style="height: 20px; cursor: pointer"
                  />
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions style="justify-content: center" class="pb-6">
            <v-btn
              color="primary"
              class="text-capitalize pl-6 pr-6 change-pwd-button"
              rounded
              :disabled="disableConfirmButton"
              block
              @click="confirmButtonDidPress"
            >
              {{ $t("CONFIRM") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-col class="copy-text">
          <CopyrightAmplifon />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { RESET_MY_PASSWORD } from "../store/actions"
export default {
  name: "ResetPassword",
  data() {
    return {
      oldPasswordVisible: false,
      newPasswordVisible: false,
      oldPassword: "",
      newPassword: "",
      fieldsFocused: [false, false],
      matchPassword: (value) =>
        value === this.oldPassword || "Invalid Password",
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.auth.recoverPwdToken,
    }),
    disableConfirmButton() {
      return this.oldPassword !== "" &&
        this.newPassword !== "" &&
        this.oldPassword === this.newPassword
        ? false
        : true
    },
    oldPasswordString() {
      return this.$t("PASSWORD")
    },
    newPasswordPlaceString() {
      return this.$t("CONFIRM_PASSWORD")
    },
  },
  methods: {
    ...mapActions([RESET_MY_PASSWORD]),
    confirmButtonDidPress() {
      this.resetPassword()
    },
    async resetPassword() {
      await this.RESET_MY_PASSWORD(this.oldPassword)
      localStorage.setItem("user-token", "")
      this.$router.push("/login")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles";
.change-pwd-container {
  padding: 32.5px 38px 67px;
  border-radius: $border-radius !important;
  box-shadow: $shadow;
  background-color: $background !important;
}
.change-pwd-button {
  text-transform: capitalize;
  padding: 12px 48px 12px 48px !important;
  font-weight: bold;
  font-size: 21px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: "Value";
}
</style>
