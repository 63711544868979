<template>
  <v-container class="fill-height" fluid style="justify-content: center">
    <v-row justify="center" class="text-center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="login-container">
          <v-img :src="require('@/assets/lr-logo.svg')" contain height="78" />
          <v-card-text class="pb-0">
            <div class="mb-6 mt-4" v-if="firstLoginFromSignupEmail">
              <strong>{{ $t("WELCOME") }}</strong
              ><br />
              <strong>{{ $t("WELCOME_MESSAGE_SIGNUP") }}</strong
              ><br />
              {{ $t("WELCOME_MESSAGE") }}
            </div>
            <div class="mb-6 mt-4" v-else>
              {{ $t("WELCOME") }} {{ $t("WELCOME_MESSAGE") }}
            </div>
            <!-- Input Fields -->
            <v-form>
              <v-text-field
                :placeholder="$t('USERNAME_OR_EMAIL')"
                name="email"
                v-model="credentials.username"
                type="text"
                :loading="loading"
                @focus="fieldsFocused.splice(0, 1, true)"
                @blur="fieldsFocused.splice(0, 1, false)"
                solo
                class="oh-input-field"
              >
                <template slot="prepend-inner">
                  <img
                    :src="
                      require(`@/assets/icons/envelope/${
                        fieldsFocused[0] ? 'envelope-active' : 'envelope'
                      }.svg`)
                    "
                    class="mr-2"
                    height="30"
                  />
                </template>
              </v-text-field>

              <v-text-field
                :placeholder="$t('PASSWORD')"
                name="password"
                v-model="credentials.password"
                :type="passwordVisible ? 'text' : 'password'"
                solo
                :loading="loading"
                @focus="fieldsFocused.splice(1, 1, true)"
                @blur="fieldsFocused.splice(1, 1, false)"
                class="oh-input-field"
              >
                <template slot="prepend-inner">
                  <img
                    :src="
                      require(`@/assets/icons/lock/${
                        fieldsFocused[1] ? 'lock-active' : 'lock'
                      }.svg`)
                    "
                    class="mr-2"
                    height="30"
                  />
                </template>

                <template slot="append">
                  <img
                    :src="
                      require(`@/assets/icons/${
                        this.passwordVisible ? 'eye-block' : 'eye'
                      }/${this.fieldsFocused[1] ? 'eye-active' : 'eye'}.svg`)
                    "
                    class="textfield-icon"
                    :color="fieldsFocused[1] ? '#C5003E' : null"
                    @click="passwordVisible = !passwordVisible"
                    style="height: 20px; cursor: pointer"
                  />
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions style="justify-content: center" class="pb-6">
            <v-btn
              color="primary"
              class="text-capitalize pl-6 pr-6 login-button"
              rounded
              :disabled="disableLoginButton"
              block
              @click="loginButtonDidPress"
              :loading="loading"
            >
              {{ $t("LOGIN") }}
            </v-btn>
          </v-card-actions>
          <div v-if="!firstLoginFromSignupEmail">
            <router-link to="/forgot-password">{{
              $t("FORGOT_PASSWORD")
            }}</router-link>
          </div>
        </v-card>
        <v-col class="copy-text">
          <CopyrightAmplifon />
        </v-col>
      </v-col>
    </v-row>
    <v-fade-transition>
      <OKAlert
        v-show="alertVisible"
        :title="$t('ERROR')"
        :message="!!error ? error.message : ''"
        dismissable
        :dismissAction="
          () => {
            alertVisible = false
          }
        "
      />
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import { AUTHENTICATE_USER, CHECK_SIGNUP_CODE } from "../store/actions"

export default {
  name: "Login",
  data() {
    return {
      passwordVisible: false,
      rememberCredentials: false,
      credentials: {
        username: "",
        password: "",
      },
      alertVisible: false,
      fieldsFocused: [false, false],
    }
  },
  computed: {
    ...mapState({
      error: (state) => state.session.error,
      loading: (state) => state.auth.loading,
    }),
    disableLoginButton() {
      return this.credentials.username !== "" &&
        this.credentials.password !== ""
        ? false
        : true
    },
    firstLoginFromSignupEmail() {
      return this.$route.query.code && this.$route.query.code.length !== 0
        ? true
        : false
    },
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue == true) {
        this.$router.push("/")
      }
    },
    error(newValue) {
      if (newValue != null) {
        this.alertVisible = true
      }
    },
  },
  methods: {
    ...mapActions([AUTHENTICATE_USER, CHECK_SIGNUP_CODE]),

    async loginButtonDidPress() {
      try {
        await this.AUTHENTICATE_USER(this.credentials)
      } catch (e) {
        console.log(e)
      }
    },

    async checkSignupCode() {
      if (this.$route.query.code) {
        try {
          await this.CHECK_SIGNUP_CODE(this.$route.query.code)
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
  async beforeMount() {
    if (this.$route.query.code) {
      await this.checkSignupCode()
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.login-container {
  padding: 32.5px 38px 67px;
  border-radius: $border-radius !important;
  box-shadow: $shadow;
  background-color: $background !important;
}
.login-button {
  text-transform: capitalize;
  padding: 12px 48px 12px 48px !important;
  font-weight: bold;
  font-size: 21px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: "Value";
}

.copy-text {
  font-family: "Poppins" !important;
}
</style>
