<template>
  <div class="loader">
    <div v-if="loading">
      <v-progress-circular
        :width="3"
        :size="200"
        color="#C5003E"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-fade-transition>
      <OKAlert
        v-show="alertVisible"
        :title="$t('ERROR')"
        :message="!!error ? error.message : ''"
        dismissable
        :dismissAction="
          () => {
            alertVisible = false
            redirectLogin()
          }
        "
      />
    </v-fade-transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { CHECK_RESET_PASSWORD_CODE } from "../store/actions"

export default {
  name: "CheckReset",
  data() {
    return {
      alertVisible: false,
    }
  },
  computed: {
    ...mapState({
      error: (state) => state.session.error,
      loading: (state) => state.auth.loading,
      token: (state) => state.auth.recoverPwdToken,
    }),
  },
  watch: {
    token() {
      localStorage.setItem("user-token", this.token)
      this.resetMyPassword()
    },
    error(newValue) {
      if (newValue != null) {
        this.alertVisible = true
      }
    },
  },
  methods: {
    ...mapActions([CHECK_RESET_PASSWORD_CODE]),
    async checkResetPasswordCode() {
      if (this.$route.query.code) {
        await this.CHECK_RESET_PASSWORD_CODE(this.$route.query.code)
      }
    },
    async resetMyPassword() {
      this.token !== "" && this.$router.push({ path: "/reset-password" })
    },
    redirectLogin() {
      this.$router.push("/login")
    },
  },
  async beforeMount() {
    if (this.$route.query.code) {
      await this.checkResetPasswordCode()
    }
  },
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-items: center;
  margin: auto;
}
</style>
