import Vue from "vue"

/* import Drawer from '../core/Drawer'


import SVGIcon from '../components/SVGIcon.vue'
import NewsDetail from '../components/NewsDetail'
import QuestionDetail from '../components/QuestionDetail'

import OHTextAreaCard from '../components/OHTextAreaCard'
import OHTextEditor from '../components/OHTextEditor'
import OHDragInputFile from '../components/OHDragInputFile'
import OHRadioButton from '../components/OHRadioButton' */
import Toolbar from "../core/Toolbar"
import Footer from "../core/Footer"
import OHDashboardCard from "../components/OHDashboardCard"
import OKAlert from "../components/OKAlert"
import OHList from "../components/OHList"
import OKCard from "../components/OKCard"
import CopyrightAmplifon from "../components/CopyrightAmplifon"
/*import OKCheckbox from '../components/OKCheckbox'
import OKFilters from '../components/OKFilters'
import OKMap from '../components/OKMap'

import OKFilterDate from '../components/filters/OKFilterDate'
import OKFilterSlider from '../components/filters/OKFilterSlider' */

export const registerComponents = () => {
  /*   Vue.component ('drawer', Drawer)
  
  
  Vue.component ('svg-icon', SVGIcon)
  Vue.component ('NewsDetail', NewsDetail)
  Vue.component ('QuestionDetail', QuestionDetail)

  Vue.component ('OHTextAreaCard', OHTextAreaCard)
  Vue.component ('OHTextEditor', OHTextEditor)
  Vue.component ('OHDragInputFile', OHDragInputFile)
  Vue.component ('OHRadioButton', OHRadioButton) */
  Vue.component("Toolbar", Toolbar)
  Vue.component("Footer", Footer)
  Vue.component("OHDashboardCard", OHDashboardCard)
  Vue.component("OKAlert", OKAlert)
  Vue.component("OHList", OHList)
  Vue.component("OKCard", OKCard)
  Vue.component("CopyrightAmplifon", CopyrightAmplifon)
  /*Vue.component ('OKCheckbox', OKCheckbox)
  Vue.component ('OKFilters', OKFilters)
  Vue.component ('OKMap', OKMap)

  Vue.component ('OKFilterDate', OKFilterDate)
  Vue.component ('OKFilterSlider', OKFilterSlider) */
}
