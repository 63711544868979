import Vue from "vue"
import VueRouter from "vue-router"
import Login from "../views/Login.vue"
import Signup from "../views/Signup.vue"
import Thankyou from "../views/Thankyou.vue"
import Welcome from "../views/Welcome.vue"
import ChangePassword from "../views/ChangePassword.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import CheckReset from "../views/CheckReset.vue"
import ResetPassword from "../views/ResetPassword.vue"

import store from "../store"
import {
  CLEAR_SESSION_MESSAGES,
  TOGGLE_OVERLAY,
  SET_CAN_CHANGE_PAGE,
  SET_ACTION_TO_PERFORM_AFTER_PROCEED,
} from "../store/mutations"

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next("/")
}

const ifAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next("/login")
    return
  }
  next()
}
const isSignupCode = (to, from, next) => {
  if (!from.query.code && !to.query.code) {
    next("/login")
    return
  }
  next()
}

const isNotSignupEmail = (to, from, next) => {
  if (!store.getters.isSignupEmail) {
    next("/login")
    return
  }
  next()
}

const routes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "Checkup",
    path: "/check-signup-code",
    component: Login,
    beforeEnter: ifNotAuthenticated && isSignupCode,
  },
  {
    name: "Signup",
    path: "/signup",
    component: Signup,
    //beforeEnter: ifNotAuthenticated,
  },
  {
    name: "Thankyou",
    path: "/thank-you",
    component: Thankyou,
    beforeEnter: isNotSignupEmail,
  },
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "CheckReset",
    path: "/check-reset-password-code",
    component: CheckReset,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "ResetPassword",
    path: "/reset-password",
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters.canChangePage) {
    next()
  } else {
    store.commit(SET_ACTION_TO_PERFORM_AFTER_PROCEED, async () => {
      router.push(to)
    })
    store.commit(TOGGLE_OVERLAY, true)
  }
})

export default router
