import Vue from "vue"
import Vuex from "vuex"

// Modules
import auth from "./modules/auth"
import session from "./modules/session"
import schools from "./modules/schools"
import ui from "./modules/ui"

// API for network requests
import { API } from "../utils/api"
import {
  MAKE_GET_REQUEST,
  MAKE_POST_REQUEST,
  MAKE_PUT_REQUEST,
} from "./actions"

import createAPIPlugin from "./plugins/api"

Vue.use(Vuex)

const apiPlugin = createAPIPlugin()

export default new Vuex.Store({
  modules: {
    auth,
    session,
    ui,
    schools,
  },
  actions: {
    [MAKE_GET_REQUEST]({ commit }, payload) {
      return API.makeCall(commit, { ...payload, method: "get" })
    },
    [MAKE_POST_REQUEST]({ commit }, payload) {
      return API.makeCall(commit, { ...payload, method: "post" })
    },
    [MAKE_PUT_REQUEST]({ commit }, payload) {
      return API.makeCall(commit, { ...payload, method: "put" })
    },
  },
  getters: {
    error: (state) => [state.auth.error].filter((err) => !!err),
    canChangePage: (state) => state.session.canChangePage,
  },
  plugins: [apiPlugin],
})
