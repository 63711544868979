import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import i18n from "./i18n"
import { registerComponents } from "./utils/components"

import VueClipboard from "vue-clipboard2"

VueClipboard.config.autoSetContainer = true

Vue.config.productionTip = false

registerComponents()

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")

Vue.use(VueClipboard)
