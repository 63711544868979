// Network Actions
export const MAKE_GET_REQUEST = "MAKE_GET_REQUEST"
export const MAKE_POST_REQUEST = "MAKE_POST_REQUEST"
export const MAKE_PUT_REQUEST = "MAKE_PUT_REQUEST"

// Authentication
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const GET_CONFIGURATIONS = "GET_CONFIGURATIONS"
export const SIGNUP = "SIGNUP"
export const CHECK_SIGNUP_CODE = "CHECK_SIGNUP_CODE"
export const RESEND_SIGNUP_EMAIL = "RESEND_SIGNUP_EMAIL"
export const CHANGE_MY_PASSWORD = "CHANGE_MY_PASSWORD"
export const GET_TEACHER_INFO = "GET_TEACHER_INFO"
export const SEND_RESET_PASSWORD_EMAIL = "SEND_RESET_PASSWORD_EMAIL"
export const CHECK_RESET_PASSWORD_CODE = "CHECK_RESET_PASSWORD_CODE"
export const RESET_MY_PASSWORD = "RESET_MY_PASSWORD"

// Contents
export const GET_GLOBAL_CONTENTS = "GET_GLOBAL_CONTENTS"
export const GET_COUNTRY_CONTENTS = "GET_COUNTRY_CONTENTS"
export const UPDATE_AMPLIFON_INFO = "UPDATE_AMPLIFON_INFO"
export const UPDATE_INFORMATIVE = "UPDATE_INFORMATIVE"
export const UPDATE_PRIVACY_INFO = "UPDATE_PRIVACY_INFO"
export const UPDATE_PRIVACY_FILE = "UPDATE_PRIVACY_FILE"
export const ADD_QUESTION = "ADD_QUESTION"
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const GET_STATS = "GET_STATS"

// News
export const GET_NEWS = "GET_NEWS"
export const GET_NEWS_DETAIL = "GET_NEWS_DETAIL"
export const ADD_NEWS = "ADD_NEWS"
export const ADD_NEWS_MEDIA = "ADD_NEWS_MEDIA"
export const EDIT_NEWS = "EDIT_NEWS"
export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_MEDIA = "DELETE_NEWS_MEDIA"
export const CHANGE_NEWS_ORDER = "CHANGE_NEWS_ORDER"

// School News
export const GET_SCHOOL_NEWS = "GET_SCHOOL_NEWS"
export const EDIT_SCHOOL_NEWS = "EDIT_SCHOOL_NEWS"
export const REFRESH_SCHOOL_NEWS = "REFRESH_SCHOOL_NEWS"
export const CHANGE_SCHOOL_NEWS_ORDER = "CHANGE_SCHOOL_NEWS_ORDER"

// Map
export const GET_HEATMAP = "GET_HEATMAP"
export const GET_PLACES = "GET_PLACES"
export const SEARCH_PLACES = "SEARCH_PLACES"
export const GET_CATEGORIES = "GET_CATEGORIES"

// Utils
export const GET_LOGS = "GET_LOGS"

// Leaderboard
export const GET_SCHOOL_RANKINGS = "GET_SCHOOL_RANKINGS"

// School Project
export const GET_SCHOOL_PROJECT_CONTENTS = "GET_SCHOOL_PROJECT_CONTENTS"
export const UPDATE_SCHOOL_PROJECT_STATE = "UPDATE_SCHOOL_PROJECT_STATE"
export const UPDATE_SCHOOL_PROJECT = "UPDATE_SCHOOL_PROJECT"

// Schools
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_SCHOOLS = "GET_SCHOOLS"
export const GET_SCHOOLS_SCROLL = "GET_SCHOOLS_SCROLL"
export const GET_SUBJECTS = "GET_SUBJECTS"
export const GET_STUDENT_CODES = "GET_STUDENT_CODES"
export const ADD_STUDENT_CODE = "ADD_STUDENT_CODE"
