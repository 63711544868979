import {
  MAKE_GET_REQUEST,
  MAKE_POST_REQUEST,
  GET_COUNTRIES,
  GET_SCHOOLS,
  GET_SCHOOLS_SCROLL,
  GET_SUBJECTS,
  GET_STUDENT_CODES,
  ADD_STUDENT_CODE,
} from "../actions"

import {
  ADD_STUDENT_CODE_MUTATION,
  GET_COUNTRIES_MUTATION,
  GET_SCHOOLS_MUTATION,
  GET_SCHOOLS_SCROLL_MUTATION,
  GET_STUDENT_CODES_MUTATION,
  GET_SUBJECTS_MUTATION,
  EMPTY_SCHOOL
} from "../mutations"

const state = {
  countries: [],
  schools: [],
  startFrom: 0,
  numRecords: 0,
  subjects: [],
  codes: [],
  selectedCountry: null,
  loading: false,
  error: null,
}

const actions = {
  [GET_COUNTRIES]({ dispatch }, language) {
    return dispatch(MAKE_GET_REQUEST, {
      endpoint: "schools/get-countries",
      mutation: GET_COUNTRIES_MUTATION,
      params: { language },
    })
  },
  [GET_SCHOOLS]({ dispatch }, { countryCode, searchString, postalCode }) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "schools/get-schools",
      mutation: GET_SCHOOLS_MUTATION,
      params: {
        countryCode: countryCode,
        startFrom: 0,
        filters: {
          searchString: searchString.trim() || "",
          postalCode: postalCode.trim() || "",
        },
      },
    })
  },
  [GET_SCHOOLS_SCROLL](
    { dispatch },
    { countryCode, searchString, postalCode }
  ) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "schools/get-schools",
      mutation: GET_SCHOOLS_SCROLL_MUTATION,
      params: {
        countryCode,
        startFrom: state.startFrom,
        filters: {
          searchString: searchString.trim() || "",
          postalCode: postalCode.trim() || "",
        },
      },
    })
  },
  [GET_SUBJECTS]({ dispatch }, payload) {
    return dispatch(MAKE_GET_REQUEST, {
      endpoint: "schools/get-subjects",
      mutation: GET_SUBJECTS_MUTATION,
      params: {
        countryCode: payload.countryCode,
        language: payload.language,
      },
    })
  },
  [GET_STUDENT_CODES]({ dispatch }, countryCode) {
    return dispatch(MAKE_GET_REQUEST, {
      endpoint: "schools/get-student-codes",
      mutation: GET_STUDENT_CODES_MUTATION,
      params: {
        countryCode: countryCode,
      },
    })
  },
  [ADD_STUDENT_CODE]({ dispatch }, payload) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "schools/add-student-code",
      mutation: ADD_STUDENT_CODE_MUTATION,
      params: {
        countryCode: payload.countryCode,
        schoolId: payload.schoolId,
        className: payload.className,
      },
    })
  },
}

const mutations = {
  // Get Countries
  [GET_COUNTRIES_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.countries = data.countries
  },
  [GET_COUNTRIES_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.countries = []
  },
  [GET_COUNTRIES_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  // Get Schools
  [GET_SCHOOLS_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.schools = data.schools
    state.startFrom = state.schools.length
    state.numRecords = data.numRecords
  },
  [GET_SCHOOLS_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.schools = []
  },
  [GET_SCHOOLS_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

// Empty School List
  [EMPTY_SCHOOL](state) {
    state.schools = []
  },

  // Get Schools Scroll
  [GET_SCHOOLS_SCROLL_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.schools = [...state.schools, ...data.schools]
    state.startFrom = state.schools.length
    state.numRecords = data.numRecords
  },
  [GET_SCHOOLS_SCROLL_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.schools = []
  },
  [GET_SCHOOLS_SCROLL_MUTATION.REQUEST](state) {
    //state.loading = true
    state.error = null
  },

  //Get Subjects
  [GET_SUBJECTS_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.subjects = data.subjects
  },
  [GET_SUBJECTS_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.subjects = []
  },
  [GET_SUBJECTS_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },
  //Get Student Codes
  [GET_STUDENT_CODES_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.codes = data.codes
  },
  [GET_STUDENT_CODES_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.codes = []
  },
  [GET_STUDENT_CODES_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  //Get Student Codes
  [ADD_STUDENT_CODE_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
  },
  [ADD_STUDENT_CODE_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
  },
  [ADD_STUDENT_CODE_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },
}

export default {
  state,
  actions,
  mutations,
}
