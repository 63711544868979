var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[!_vm.borderless ? 'ok-card' : 'ok-card-borderless']},[_c('v-container',{staticClass:"cc"},[_c('v-row',{class:[
        'flex-grow-0 flex-shrink-0 align-center justify-space-between',
        _vm.fixedHeader && 'ok-card-fixed-header' ],staticStyle:{"margin-top":"12px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"card-title font-weight-bold d-flex align-center",attrs:{"cols":"auto","align-self":"center"}},[(_vm.draggable)?_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/drag-icon.svg'),"contain":"","height":"30"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c('v-col',{class:[
          _vm.close && 'mr-4',
          'd-flex',
          'align-center flex-grow-1 justify-end' ],attrs:{"cols":"auto"}},[_vm._t("topActions"),(_vm.refresh)?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){!!_vm.refresh()}}},[_c('img',{attrs:{"src":require('@/assets/icons/refresh.svg'),"height":"20","contain":""}})]):_vm._e(),(_vm.collapsable)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_c('v-icon',{class:['collapse-icon', _vm.collapse && 'collapse-icon-closed'],attrs:{"color":"primary"}},[_vm._v("fas fa-caret-up")])],1):_vm._e(),(_vm.close)?_c('v-btn',{attrs:{"text":"","icon":"","id":"dismiss-icon"},on:{"click":function($event){!!_vm.close()}}},[_c('img',{attrs:{"src":require('@/assets/icons/dismiss.svg'),"height":"28","contain":""}})]):_vm._e()],2)],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapse),expression:"!collapse"}],class:[
          'flex-grow-1 flex-shrink-0',
          _vm.fixedHeader && 'mt-6',
          'content-row' ]},[_vm._t("content")],2)],1),_c('v-row',{staticClass:"ok-card-footer"},[_vm._t("footer")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }