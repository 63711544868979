<template>
  <v-container class="fill-height" fluid style="justify-content: center">
    <v-row justify="center" class="text-center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="change-pwd-container">
          <v-img :src="require('@/assets/lr-logo.svg')" contain height="78" />
          <v-card-text class="pb-0 pt-3">
            <div class="mb-6 mt-4">
              <br />
              <p>{{ $t("FORGOT_PASSWORD_MESSAGE") }}</p>
            </div>
            <v-form ref="form">
              <v-text-field
                :placeholder="$t('EMAIL')"
                name="email"
                v-model="email"
                type="text"
                solo
                class="oh-input-field"
                @focus="fieldsFocused = true"
              >
                <template slot="prepend-inner">
                  <img
                    :src="
                      require(`@/assets/icons/envelope/${fieldsFocused ? 'envelope-active' : 'envelope'
                        }.svg`)
                    "
                    class="mr-2"
                    height="30"
                  />
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions style="justify-content: center" class="pb-6">
            <v-btn
              color="primary"
              class="text-capitalize pl-6 pr-6 change-pwd-button"
              rounded
              :disabled="disableSendButton"
              :loading="loading"
              block
              @click="sendButtonDidPress"
            >{{ $t("SEND_EMAIL") }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-col class="copy-text">
          <CopyrightAmplifon />
        </v-col>
      </v-col>
    </v-row>
    <v-fade-transition>
      <OKAlert
        v-show="alertErrorVisible"
        :title="$t('ERROR')"
        :message="!!error ? $t('EMAIL_NOT_FOUND') : ''"
        dismissable
        :dismissAction="
          () => {
            alertErrorVisible = false
          }
        "
      />
    </v-fade-transition>
    <v-fade-transition>
      <OKAlert
        v-show="alertConfirmVisible"
        :title="$t('SUCCESS')"
        :message="$t('SEND_EMAIL_PASSWORD_SUCCESS')"
        dismissable
        :dismissAction="
          () => {
            alertConfirmVisible = false
          }
        "
      />
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { SEND_RESET_PASSWORD_EMAIL } from "../store/actions"
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      fieldsFocused: false,
      alertErrorVisible: false,
      alertConfirmVisible: false,
    }
  },
  computed: {
    ...mapState({
      error: (state) => state.auth.error,
      loading: (state) => state.auth.loading,
    }),
    disableSendButton() {
      return this.email === "" ? true : false
    },
  },
  watch: {
    error(newValue) {
      if (newValue != null) {
        this.alertErrorVisible = true
      }
    },
  },
  methods: {
    ...mapActions([SEND_RESET_PASSWORD_EMAIL]),
    sendButtonDidPress() {
      this.sendResetPasswordEmail()
    },

    async sendResetPasswordEmail() {
      await this.SEND_RESET_PASSWORD_EMAIL(this.email)
      if (this.error === null && !this.loading) {
        this.alertConfirmVisible = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles";
.change-pwd-container {
  padding: 32.5px 38px 67px;
  border-radius: $border-radius !important;
  box-shadow: $shadow;
  background-color: $background !important;
}
.change-pwd-button {
  text-transform: capitalize;
  padding: 12px 48px 12px 48px !important;
  font-weight: bold;
  font-size: 21px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: "Value";
}
</style>
