import {
  LOGIN,
  GET_CONFIGURATIONS_MUTATION,
  LOGOUT,
  SET_SELECTED_COUNTRY,
  AUTH_STANDARD_MUTATION,
  GET_TEACHER_INFO_MUTATION,
  SIGNUP_MUTATION,
  CHECK_RESET_PASSWORD_CODE_MUTATION,
  /*
  CHECK_SIGNUP_CODE_MUTATION,
  RESEND_SIGNUP_EMAIL_MUTATION,
  CHANGE_MY_PASSWORD_MUTATION */
} from "../mutations"
import {
  MAKE_POST_REQUEST,
  AUTHENTICATE_USER,
  GET_CONFIGURATIONS,
  MAKE_GET_REQUEST,
  SIGNUP,
  CHECK_SIGNUP_CODE,
  RESEND_SIGNUP_EMAIL,
  CHANGE_MY_PASSWORD,
  GET_TEACHER_INFO,
  SEND_RESET_PASSWORD_EMAIL,
  CHECK_RESET_PASSWORD_CODE,
  RESET_MY_PASSWORD,
} from "../actions"

const state = {
  user: JSON.parse(localStorage.getItem("user-info")) || null,
  token: localStorage.getItem("user-token") || "",
  teacherInfo: {},
  signupInfo: {},
  recoverPwdToken: "",
  configurations:
    JSON.parse(localStorage.getItem("user-configurations")) || null,
  selectedCountry: localStorage.getItem("user-selectedCountry") || "",
  loading: false,
  error: null,
}

const actions = {
  /*
   *	Check user username and password and returns the token to use on the subsequent requests.
   * NOTE: In the response also the 'userSettings' are returned.
   * Inside these settings the important parameter is defaultCountry that indicates the country to use in all the initial requests of other entities, like news, privacy policies, etc
   *
   * Params:
   *	{
   *		username: string
   *		password: string
   *	}
   */
  [AUTHENTICATE_USER]({ dispatch }, credentials) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/authenticate",
      mutation: LOGIN,
      params: {
        username: credentials.username,
        password: credentials.password,
      },
    })
  },

  /*
   * This API is used to retrieve all the info needed initially by the user.
   * The user calling this API gets all the countries and required languages for every country.
   * This info is useful for example when a user adds or edit a news, because a news cannot be saved without the translation in all the languages required for a country.
   * The user gets also all the categories assignable to the news
   */
  [GET_CONFIGURATIONS]({ dispatch }) {
    return dispatch(MAKE_GET_REQUEST, {
      endpoint: "users/get-configurations",
      mutation: GET_CONFIGURATIONS_MUTATION,
    })
  },

  /****/
  [SIGNUP]({ dispatch }, credentials) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/signup",
      mutation: SIGNUP_MUTATION,
      params: {
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        password: credentials.password,
        email: credentials.email,
        phone: credentials.phone,
        countryCode: credentials.countryCode,
        language: credentials.language,
        position: credentials.position,
        subjectId: credentials.subjectId,
        schoolId: credentials.schoolId,
        privacyConsent: credentials.privacyConsent,
        promoConsent: credentials.promoConsent
      },
    })
  },

  [CHECK_SIGNUP_CODE]({ dispatch }, code) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/check-signup-code",
      mutation: AUTH_STANDARD_MUTATION,
      //mutation: CHECK_SIGNUP_CODE_MUTATION,
      params: { code },
    })
  },

  [RESEND_SIGNUP_EMAIL]({ dispatch }, email) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/resend-signup-email",
      mutation: AUTH_STANDARD_MUTATION,
      //mutation: RESEND_SIGNUP_EMAIL_MUTATION,
      params: { email },
    })
  },

  [CHANGE_MY_PASSWORD]({ dispatch }, chgPwdCredentials) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/change-my-password",
      mutation: AUTH_STANDARD_MUTATION,
      //mutation: CHANGE_MY_PASSWORD_MUTATION,
      params: {
        oldPassword: chgPwdCredentials.oldPassword,
        newPassword: chgPwdCredentials.newPassword,
      },
    })
  },

  [GET_TEACHER_INFO]({ dispatch }) {
    return dispatch(MAKE_GET_REQUEST, {
      endpoint: "users/get-teacher-info",
      mutation: GET_TEACHER_INFO_MUTATION,
      params: {},
    })
  },

  // Reset Password
  [SEND_RESET_PASSWORD_EMAIL]({ dispatch }, email) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/send-reset-password-email",
      mutation: AUTH_STANDARD_MUTATION,
      params: { email },
    })
  },

  [CHECK_RESET_PASSWORD_CODE]({ dispatch }, code) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/check-reset-password-code",
      mutation: CHECK_RESET_PASSWORD_CODE_MUTATION,
      params: { code },
    })
  },

  [RESET_MY_PASSWORD]({ dispatch }, pwdToken) {
    return dispatch(MAKE_POST_REQUEST, {
      endpoint: "users/reset-my-password",
      mutation: AUTH_STANDARD_MUTATION,
      params: { password: pwdToken },
    })
  },
}

const mutations = {
  // Login
  [LOGIN.SUCCESS](state, { data }) {
    state.loading = false
    state.token = data.token
    state.user = data.user
    localStorage.setItem("user-info", JSON.stringify(data.user))
    localStorage.setItem("user-token", data.token)
  },

  [LOGIN.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.token = ""
    state.user = null
  },

  [LOGIN.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  // Get Configurations
  [GET_CONFIGURATIONS_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.configurations = data.configurations
    localStorage.setItem(
      "user-configurations",
      JSON.stringify(data.configurations)
    )
  },

  [GET_CONFIGURATIONS_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
    state.configurations = null
  },

  [GET_CONFIGURATIONS_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  // Set Selected Country
  [SET_SELECTED_COUNTRY](state, country) {
    state.selectedCountry = country
    localStorage.setItem("user-selectedCountry", country)
  },

  // Logout
  [LOGOUT](state) {
    localStorage.removeItem("user-token")
    localStorage.removeItem("user-info")
    localStorage.removeItem("user-configurations")
    localStorage.removeItem("user-selectedCountry")
    state.token = null
    state.user = null
    state.configurations = null
    state.selectedCountry = null
  },

  [AUTH_STANDARD_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
  },

  [AUTH_STANDARD_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.error = error
  },

  [AUTH_STANDARD_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  [GET_TEACHER_INFO_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.teacherInfo = data
  },

  [GET_TEACHER_INFO_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.teacherInfo = {}
    state.error = error
  },

  [GET_TEACHER_INFO_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  [SIGNUP_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.signupInfo = data
  },

  [SIGNUP_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.signupInfo = {}
    state.error = error
  },

  [SIGNUP_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },

  [CHECK_RESET_PASSWORD_CODE_MUTATION.SUCCESS](state, { data }) {
    state.loading = false
    state.recoverPwdToken = data.token
  },

  [CHECK_RESET_PASSWORD_CODE_MUTATION.FAILURE](state, { error }) {
    state.loading = false
    state.recoverPwdToken = ""
    state.error = error
  },

  [CHECK_RESET_PASSWORD_CODE_MUTATION.REQUEST](state) {
    state.loading = true
    state.error = null
  },
}

const getters = {
  isAuthenticated: (state) => !!state.token,
  isSignupEmail: (state) => !!state.signupInfo.email,
  role: (state) => (state.user ? state.user.role : undefined),
  languagesForSelectedCountry: (state) => {
    if (!state.configurations) {
      return []
    }
    let country = state.configurations.countries.find((country) => {
      return country.countryCode === state.selectedCountry
    })
    return country ? country.languages : []
  },
  questionnaireLanguages: (state) =>
    state.configurations && state.configurations.questionnaireLanguages
      ? state.configurations.questionnaireLanguages
      : [],
}

function checkRole(role) {
  try {
    // Decode the token and extract the role
    if (!state.token) {
      return false
    }
    let extractedRole = JSON.parse(atob(state.token.split(".")[1])).role
    return extractedRole == role
  } catch (e) {
    return false
  }
}

export const AccountRole = {
  SUPER_ADMIN: "SuperAdmin",
  CORPORATE: "Corporate",
  COUNTRY: "Country",
}

export default {
  state,
  actions,
  mutations,
  getters,
}
