import "@fortawesome/fontawesome-free/css/all.css" // Ensure you are using css-loader
import Vue from "vue"
import Vuetify from "vuetify/lib"

//import '../styles/variables.scss'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#C5003E",
        background: "#F7F7F7",
        darkGrey: "#222222",
        mediumGrey: "#444444",
        lightGrey: "#888888",
        veryLightGrey: "#E6E7E9",
        accent: "#8c9eff",
        error: "#B50061",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  // values: {
  //     custom: {
  //         award: require('@/assets/img/award.png')
  //     }
  // }
})
