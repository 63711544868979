<template>
  <v-overlay>
    <v-card color="white" class="ok-alert">
      <v-card-title class="darkGrey--text font-weight-bold">
        {{ title }}
      </v-card-title>

      <v-card-text class="message">
        {{ message }}
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-row class="justify-center">
          <v-col cols="4" v-for="action in actions" :key="action.title">
            <v-btn
              block
              @click="action.handler"
              color="primary"
              class="confirm-button"
              elevation="0"
              rounded
              :outlined="action.outlined"
            >
              {{ action.title }}
            </v-btn>
          </v-col>
          <v-col v-if="dismissable" cols="auto">
            <v-btn
              @click="dismissAction()"
              color="primary"
              class="confirm-button text-capitalize"
              rounded
              elevation="0"
            >
              {{ $t("DISMISS") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "OKAlert",
  props: {
    // The prop that will show or hide the overlay
    overlay: {
      type: Boolean,
    },
    // If true, the alert will have a dismiss action by default
    dismissable: {
      type: Boolean,
      default: false,
    },
    // Title and message to show in the alert
    title: String,
    message: String,
    dismissAction: {
      type: Function,
    },
    // Array of actions to add to the alert. Each action must have a title and a handler
    actions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles";

.ok-alert {
  min-width: 300px;
  background-color: $background !important;
  border-radius: 20px !important;
  box-shadow: $shadow !important;

  .message {
    color: $medium-grey;
  }

  .confirm-button {
    text-transform: capitalize;
    padding: 12px 48px 12px 48px !important;
    font-weight: bold;
    font-size: 21px;
    min-width: 50% !important;
    font-family: "Value";
  }

  .confirm-button.v-btn--outlined {
    text-transform: capitalize;
    padding: 12px 48px 12px 48px !important;
    font-weight: bold;
    font-size: 21px;
    min-width: 50% !important;
    border-width: 3px;
    font-family: "Value";
  }
}
</style>
