import { axiosInstance } from "../../utils/api"

import {
  MAKE_GET_REQUEST,
  MAKE_POST_REQUEST,
  MAKE_PUT_REQUEST,
} from "../actions"

import { LOGOUT } from "../mutations"

// Called before each API action, check if the JWT is exiperd: if yes, redirect to login page
export default function createAPIPlugin() {
  return (store) => {
    store.subscribeAction({
      before: (action) => {
        switch (action.type) {
          case MAKE_GET_REQUEST:
          case MAKE_POST_REQUEST:
          case MAKE_PUT_REQUEST:
            axiosInstance.interceptors.response.use(
              (response) => {
                return response
              },
              (error) => {
                if (
                  error.response.status === 401 &&
                  store.getters.isAuthenticated
                ) {
                  // Token exiperd: logout
                  store.commit(LOGOUT)
                } else {
                  Promise.reject(error)
                }
              }
            )
            break

          default:
            break
        }
      },
    })
  }
}
