import i18n from "../../i18n"

import {
  LOGIN,
  SIGNUP_MUTATION,
  CHECK_RESET_PASSWORD_CODE_MUTATION,
  GET_CONFIGURATIONS_MUTATION,
  GET_GLOBAL_CONTENTS_MUTATION,
  GET_COUNTRY_CONTENTS_MUTATION,
  UPDATE_AMPLIFON_INFO_MUTATION,
  UPDATE_INFORMATIVE_MUTATION,
  UPDATE_PRIVACY_INFO_MUTATION,
  UPDATE_PRIVACY_FILE_MUTATION,
  GET_PLACES_MUTATION,
  SEARCH_PLACES_MUTATION,
  GET_CATEGORIES_MUTATION,
  GET_LOGS_MUTATION,
  GET_SCHOOL_RANKINGS_MUTATION,
  GET_SCHOOL_PROJECT_CONTENTS_MUTATION,
  UPDATE_SCHOOL_PROJECT_MUTATION,
  UPDATE_SCHOOL_PROJECT_STATE_MUTATION,
  GET_SCHOOL_NEWS_MUTATION,
  EDIT_SCHOOL_NEWS_MUTATION,
  REFRESH_SCHOOL_NEWS_MUTATION,
  CHANGE_SCHOOL_NEWS_ORDER_MUTATION,
  SET_CAN_CHANGE_PAGE,
  PERFORM_ON_PROCEED,
  SET_ACTION_TO_PERFORM_ON_PROCEED,
  SET_ACTION_TO_PERFORM_AFTER_PROCEED,
  CLEAR_SESSION_MESSAGES,
  SET_SESSION_MESSAGE,
} from "../mutations"

// Manage a generic error/success message manager for all the modules
const state = {
  error: null,
  success: null,
  canChangePage: true,
  actionToPerformOnProceed: undefined,
  actionToPerformAfterProceed: undefined,
}

const actions = {}

const mutations = {
  [SET_SESSION_MESSAGE](state, message) {
    state.success = message
  },

  [SET_CAN_CHANGE_PAGE](state, newStatus) {
    state.canChangePage = newStatus
  },
  [SET_ACTION_TO_PERFORM_ON_PROCEED](state, action) {
    state.actionToPerformOnProceed = action
  },
  [SET_ACTION_TO_PERFORM_AFTER_PROCEED](state, action) {
    state.actionToPerformAfterProceed = action
  },
  async [PERFORM_ON_PROCEED](state) {
    try {
      let action = state.actionToPerformOnProceed
      state.canChangePage = true
      state.actionToPerformOnProceed = undefined
      if (action) {
        await action()
      }

      let actionAfter = state.actionToPerformAfterProceed
      state.actionToPerformAfterProceed = undefined
      if (actionAfter) {
        await actionAfter()
      }
    } catch (e) {
      console.log(e)
    }
  },

  [CLEAR_SESSION_MESSAGES](state) {
    state.error = null
    state.success = null
  },

  [LOGIN.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_LOGIN")
    state.error = error
  },

  [SIGNUP_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("EMAIL_ALREADY_USED")
    state.error = error
  },

  [CHECK_RESET_PASSWORD_CODE_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_VALID_CODE")
    state.error = error
  },

  [GET_CONFIGURATIONS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_CONFIGURATIONS")
    state.error = error
  },

  [GET_GLOBAL_CONTENTS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_GLOBAL_CONTENTS")
    state.error = error
  },

  [GET_COUNTRY_CONTENTS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_COUNTRY_CONTENTS")
    state.error = error
  },

  [UPDATE_AMPLIFON_INFO_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_UPDATE_AMPLIFON_INFO")
    state.error = error
  },

  [UPDATE_INFORMATIVE_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_UPDATE_INFORMATIVE")
    state.error = error
  },

  [UPDATE_PRIVACY_INFO_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_UPDATE_PRIVACY_INFO")
    state.error = error
  },

  [UPDATE_PRIVACY_FILE_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_UPDATE_PRIVACY_FILE")
    state.error = error
  },

  [GET_PLACES_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_PLACES")
    state.error = error
  },

  [SEARCH_PLACES_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_SEARCH_PLACES")
    state.error = error
  },

  [GET_CATEGORIES_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_CATEGORIES")
    state.error = error
  },

  [GET_LOGS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_LOGS")
    state.error = error
  },

  [GET_SCHOOL_RANKINGS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_SCHOOL_RANKINGS")
    state.error = error
  },

  [GET_SCHOOL_PROJECT_CONTENTS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_SCHOOL_PROJECT_CONTENTS")
    state.error = error
  },

  [UPDATE_SCHOOL_PROJECT_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_UPDATE_SCHOOL_PROJECT")
    state.error = error
  },

  [UPDATE_SCHOOL_PROJECT_STATE_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_UPDATE_SCHOOL_PROJECT_STATE")
    state.error = error
  },

  [GET_SCHOOL_NEWS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_GET_SCHOOL_NEWS_MUTATION")
    state.error = error
  },
  [EDIT_SCHOOL_NEWS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_EDIT_SCHOOL_NEWS_MUTATION")
    state.error = error
  },
  [REFRESH_SCHOOL_NEWS_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_REFRESH_SCHOOL_NEWS_MUTATION")
    state.error = error
  },
  [CHANGE_SCHOOL_NEWS_ORDER_MUTATION.FAILURE](state, { error }) {
    error.message = i18n.t("ERROR_CHANGE_SCHOOL_NEWS_ORDER_MUTATION")
    state.error = error
  },
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
