import axios from "axios"
import { normalize } from "normalizr"
import CRC32 from "crc-32"

// DEV URL
//const apiURL = "https://teacherportalapidev.otohub.com"

// PROD
const apiURL = "https://csdteacherportalapi.otohub.com"

// Get token from localStorage
const token = () => localStorage.getItem("user-token")

export const axiosInstance = axios.create({
  baseURL: apiURL,
})

export const API = {
  makeCall: (
    commit,
    {
      endpoint,
      mutation,
      method,
      params,
      scheme,
      payload,
      responseType,
      formData,
    }
  ) => {
    const {
      REQUEST: requestMutation,
      SUCCESS: successMutation,
      FAILURE: failedMutation,
    } = mutation

    // Execute first mutation (REQUEST)
    commit(requestMutation, params)

    if (!responseType) responseType = "json"

    // Create axios config and add the parameters
    // in url or in body basing on request method
    let axiosConfig = {
      responseType,
      method,
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }

    if (method == "get") {
      axiosConfig.params = {
        ...params,
      }
    } else {
      axiosConfig.data = {
        ...params,
      }
    }

    if (formData) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        "Content-Type": "multipart/form-data",
      }
      let data = new FormData()
      Object.keys(formData).forEach((key) => {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((element) => {
            data.append(key, element)
          })
        } else {
          data.append(key, formData[key])
        }
      })
      axiosConfig.data = data
    }

    // Perform network request
    return axiosInstance(`${endpoint}`, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          const contentType = response.headers["content-type"]
          const isJSON = contentType.includes("application/json")
          if (isJSON && response.data.success === true) {
            var { result: responseData } = response.data
            if (responseData === null) {
              commit(successMutation)
              return
            }

            if (scheme != null) {
              const { entities, result: ids } = normalize(responseData, scheme)
              const schemeKey = Array.isArray(scheme)
                ? scheme[0].key
                : scheme.key

              responseData = {
                [schemeKey]: entities[schemeKey],
                ids,
                payload,
              }
            }

            commit(successMutation, {
              data: responseData,
              payload,
            })
          } else if (isJSON && response.data.success === false) {
            throw new Error(response.error)
          } else if (
            contentType.includes("image/png") ||
            contentType.includes("image/jepg") ||
            contentType.includes("application/pdf")
          ) {
            commit(successMutation, {
              data: URL.createObjectURL(response.data),
              payload,
            })
          }
        } else {
          throw new Error(response)
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          commit(failedMutation, {
            error: error.response.data.error,
            payload,
          })
          throw new Error(response)
        }

        const status =
          error.response && error.response.status ? error.response.status : 500

        switch (status) {
          case 404:
            error = {
              message:
                "Il server non ha risposto correttamente alla richiesta.",
              code: `API/${CRC32.str(requestMutation)
                .toString(16)
                .toUpperCase()
                .replace("-", "")}`,
            }
            break
          case 500:
            error = {
              message: "Il server è offline.",
            }
            break
          default:
            break
        }
        commit(failedMutation, {
          error,
          payload,
        })
        throw new Error(error)
      })
  },
}
