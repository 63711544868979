import { TOGGLE_DRAWER, TOGGLE_OVERLAY, TOGGLE_PROFILE } from "../mutations"

const state = {
  drawer: true,
  overlay: false,
  // True when the profile section must be shown
  profile: false,
}

const actions = {}

const mutations = {
  [TOGGLE_DRAWER](state, payload) {
    state.drawer = payload
  },

  [TOGGLE_OVERLAY](state, payload) {
    if (payload !== undefined) {
      state.overlay = payload
      return
    }
    state.overlay = !state.overlay
  },

  [TOGGLE_PROFILE](state) {
    state.overlay = !state.overlay
    state.profile = !state.profile
  },
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
