<template>
  <v-container class="fill-height" fluid style="justify-content: center">
    <v-row justify="center" class="text-center">
      <v-col cols="12" sm="8" md="6" lg="6">
        <v-card class="thankyou-container">
          <v-row class="d-flex justify-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/signup" class="back-text" >
                <span v-bind="attrs" v-on="on">
                  {{
                    $t("WRONG_EMAIL")
                  }}
                  </span>
                </router-link>
              </template>
              <span>{{ $t("WRONG_EMAIL_SIGNUP_MESSAGE") }}</span>
            </v-tooltip>
          </v-row>
          <v-img :src="require('@/assets/icons/lr-logo-mini.svg')" contain height="78" />
          <v-card-text class="pb-0">
            <div class="mb-6 mt-3">
              <div>
                <strong>{{ $t("THANK_YOU_TITLE") }}</strong>
              </div>
              <div class="mt-3">
                <p>
                  {{ $t("THANK_YOU_MESSAGE_START") }}
                  <strong>{{ signupEmail }}</strong>
                  {{ $t("THANK_YOU_MESSAGE_END") }}
                </p>
              </div>
            </div>
          </v-card-text>

          <v-card-actions style="justify-content: center" class="pb-6">
            <v-btn
              color="primary"
              class="text-capitalize pl-6 pr-6 thankyou-button"
              rounded
              block
              @click="sendEmail()"
            >{{ $t("RESEND_EMAIL") }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-col class="copy-text">
          <CopyrightAmplifon />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { mapState, mapActions } from "vuex"
import { RESEND_SIGNUP_EMAIL } from "../store/actions"
export default {
  name: "Thankyou",
  computed: {
    ...mapState({
      signupEmail: (state) => state.auth.signupInfo.email,
    }),
  },
  methods: {
    ...mapActions([RESEND_SIGNUP_EMAIL]),
    async sendEmail() {
      if (this.signupEmail) {
        await this.RESEND_SIGNUP_EMAIL(this.signupEmail)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.thankyou-container {
  padding: 32.5px 38px 67px;
  border-radius: $border-radius !important;
  box-shadow: $shadow;
  background-color: $background;
}
.thankyou-button {
  text-transform: capitalize;
  padding: 12px 48px 12px 48px !important;
  font-weight: bold;
  font-size: 21px;
  height: inherit !important;
  min-width: inherit !important;
  font-family: "Value";
}

.back-text {
  text-decoration: underline;
}
</style>
