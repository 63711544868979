<template>
  <div>
    <v-form>
      <v-row>
        <v-col sm="8">
          <v-text-field
            @input="debounceSearchSchoolName"
            class="oh-input-field"
            :placeholder="searchPlaceHolder"
            minlength="3"
            solo
            clearable
          >
            <template slot="prepend-inner">
              <img :src="require('@/assets/icons/search-icon.svg')" class="mr-2" height="30" />
            </template>
          </v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field
            @input="debounceSearchZipCode"
            class="oh-input-field"
            :placeholder="zipCodePlaceHolder"
            solo
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row id="infinite-list" class="school-search" v-if="!loading">
        <v-col sm="10" v-if="paginatedSchools.length === 0">
          <p>
            {{ $t("CANNOT_FIND_SCHOOL_START") }}
            <a :href="`mailto:${cannotFindSchoolEmail}`">
              {{
                $t("CANNOT_FIND_SCHOOL_EMAIL")
              }}
            </a>
            {{ $t("CANNOT_FIND_SCHOOL_END") }}
          </p>
        </v-col>
        <v-col sm="12" md="6" v-for="item in paginatedSchools" :key="item.id">
          <div
            class="school-search-detail"
            @click="handleSelectSchool(item.id)"
            :class="[selectedSchool === item.id && 'selected-school-detail']"
          >
            <div class="school-search-detail__wrapper">
              <p class="school-search-detail__title">{{ item.name }}</p>
              <img :src="require('@/assets/icons/pointer_map.svg')" alt="pin" />

              <p class="content">
                {{ item.address }}
                <br />
                <span style="padding-left: 1.3rem">
                  {{
                    completeAddress(
                      item.postalCode,
                      item.municipality,
                      item.province
                    )
                  }}
                </span>
              </p>
            </div>
          </div>
        </v-col>
        <Observer @intersect="intersected" />
      </v-row>
      <v-row v-else>
        <v-col sm="5"></v-col>
        <v-col sm="2">
          <div>
            <v-progress-circular :width="3" :size="100" color="#C5003E" indeterminate>
              <span>{{ $t("LOADING") }}</span>
            </v-progress-circular>
          </div>
        </v-col>
        <v-col sm="5"></v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { GET_SCHOOLS, GET_SCHOOLS_SCROLL } from "../store/actions"
import { EMPTY_SCHOOL } from "../store/mutations"
import Observer from "./Observer.vue"

export default {
  name: "SelectSchool",
  components: {
    Observer,
  },
  props: {
    flagReset: {
      type: Number,
    }

  },
  data() {
    return {
      schoolName: "",
      zipCode: "",
      selectedSchool: null,
      debounce: null,
      paginatedSchools: [],
    }
  },
  computed: {
    ...mapState({
      schools: (state) => state.schools.schools,
      startFrom: (state) => state.schools.startFrom,
      numRecords: (state) => state.schools.numRecords,
      selectedCountry: (state) => state.schools.selectedCountry,
      loading: (state) => state.schools.loading,
    }),
    searchPlaceHolder() {
      return this.$t("SEARCH")
    },
    zipCodePlaceHolder() {
      return this.$t("ZIP_CODE")
    },
    cannotFindSchoolEmail() {
      return this.$t("CANNOT_FIND_SCHOOL_EMAIL")
    }
  },
  watch: {
    flagReset(newPass, oldPass) {
      if (newPass < 2) this.resetSchoolList()
    }
  },
  methods: {
    ...mapActions([GET_SCHOOLS, GET_SCHOOLS_SCROLL]),
    ...mapMutations([EMPTY_SCHOOL]),
    completeAddress(zip, city, state) {
      return `${zip} ${city} ${state}`
    },
    resetSchoolList() {
      this.EMPTY_SCHOOL()
      this.paginatedSchools = []
    },
    debounceSearchSchoolName(event) {
      this.schoolName = ""
      this.$store.state.schools.loading = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.schoolName = event
        this.fetchSchoolWithFilters()
        this.$store.state.schools.loading = false
      }, 800)
    },
    debounceSearchZipCode(event) {
      this.zipCode = ""
      this.$store.state.schools.loading = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.zipCode = event
        this.fetchSchoolWithFilters()
        this.$store.state.schools.loading = false
      }, 800)
    },
    async fetchSchoolWithFilters() {
      if (this.schoolName === null) this.schoolName = ""
      if (this.zipCode === null) this.zipCode = ""
      await this.GET_SCHOOLS({
        countryCode: this.selectedCountry,
        searchString: this.schoolName,
        postalCode: this.zipCode,
      })
      this.paginatedSchools = [...this.schools]
    },
    handleSelectSchool(item) {
      this.selectedSchool = item
      this.$emit("handleSelectSchool", item)
    },
    async intersected() {
      if (this.startFrom > 0 && this.startFrom < this.numRecords && this.flagReset === 2) {
        await this.GET_SCHOOLS_SCROLL({
          countryCode: this.selectedCountry,
          searchString: this.schoolName,
          postalCode: this.zipCode,
        })
        this.paginatedSchools = [...this.schools]
      } else if (this.flagReset < 2) {
        this.EMPTY_SCHOOL()
      } else {
        this.paginatedSchools = [...this.schools]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

a {
  color: $primary !important;
  transition: color 0.2s;
  text-decoration: none;
}

.school-search {
  height: 27rem;
  overflow: scroll;
  margin-left: 7px;
  margin-right: 7px;
}

.school-search-detail {
  border-radius: 6px;
  border: 1px solid #e6e7e9;
  background-color: #ffffff;
  padding: 5px 5px;
  margin-bottom: 5px;
  min-height: 7rem;
  max-height: 7.2rem;
  cursor: pointer;
  padding: 3px;
  overflow-y: hidden;

  &__wrapper {
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 23px;
  }

  &__title {
    margin: 0 0;
    font-family: Value;
    font-weight: normal;
    font-size: 18px;
    color: #222222;
  }
  .content {
    font-size: 14px;
    display: inline;
  }
}

.selected-school-detail {
  border: solid 2px #c5003e;
}
</style>
